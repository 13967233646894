<h2>Перечень мероприятий по проекту</h2>
<table class="whiteTable">
    <tr>
        <td>Проект</td>
        <td>
            <div class="ng-autocomplete" style="width: 100%;border: 2px solid #1C6EA4;display: inline-flex;margin: 10px 0;">
                <ng-autocomplete #autocomplete
                                 [data]="data_autocomplete"
                                 [searchKeyword]="keyword"
                                 [isLoading]="check_load()"
                                 [disabled]="check_load()"
                                 placeHolder="Все проекты..."
                                 (selected)="selectEvent($event)"
                                 (inputCleared)="selectEvent({id: 0, name: 'Все проекты', curator: '-', rukovoditel: '-'})"
                                 [itemTemplate]="itemTemplate"
                                 [notFoundTemplate]="notFoundTemplate"
                                 debounceTime="1000"
                                 notFoundText="Не найдено">
                </ng-autocomplete>
                <button class="icon-search" style="color: white;width: 8%;background: #1C6EA4;border: 1px #1C6EA4"></button>
                <ng-template #itemTemplate let-item >
                    <a [innerHTML]="item.name" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis; padding: 5px 5px;"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template>
            </div>
            <select [(ngModel)]="selectedOption"
                    [compareWith]="compareByHref"
                    (change)="handleUserSubmit()"
                    [hidden]="true">
                <option
                        *ngFor="let value of valueOptions"
                        [attr.selected]="compareByHref(selectedOption, value) || undefined"
                        [attr.label]="value.name"
                        [ngValue]="value"
                        [textContent]="value.name">
                </option>
            </select>
        </td>
    </tr>

    <tr>
        <td>Куратор</td>
        <td><b>{{curator}}</b></td>
    </tr>

    <tr>
        <td>Руководитель</td>
        <td><b>{{ruk}}</b></td>
    </tr>

    <tr>
        <td>Фильтр</td>
        <td>
            <a href="" (click)="this.blueChild.changeFilter('all'); predstoyashie=false;">Все</a> /
            <a href="" (click)="this.blueChild.changeFilter('vsrok'); predstoyashie=false;">Исполнено в срок</a> /
            <a href="" (click)="this.blueChild.changeFilter('sopozdaniem'); predstoyashie=false;">Исполнено с опозданием</a> /
            <a href="" (click)="this.blueChild.changeFilter('vrabote'); predstoyashie=false;">В работе</a> /
            <a href="" (click)="this.blueChild.changeFilter('predstoyashie'); predstoyashie=true;">Предстоящие</a> /
            <a href="" (click)="this.blueChild.changeFilter('ne-ispolneno'); predstoyashie=false;">Не исполнено</a>
        </td>
    </tr>

    <tr *ngIf="predstoyashie">
        <td>Предстоящие</td>
        <td>
            <u><a href="" (click)="this.blueChild.limitDays(3)">3 дня</a></u>&nbsp;
            <u><a href="" (click)="this.blueChild.limitDays(14)">2 недели</a></u>&nbsp;
            <u><a href="" (click)="this.blueChild.limitDays(30)">1 месяц</a></u>&nbsp;
            <u><a href="" (click)="this.blueChild.limitDays(90)">3 месяца</a></u>
        </td>
    </tr>
</table>

<homescreen-blue-table [template]="'kt'"></homescreen-blue-table>
