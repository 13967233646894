<div class="container-f" style="margin: auto; max-width: 1200px; padding: 0 30px;">
    <div class="container-f" style="justify-content: flex-start;">
        <img src="https://egov-buryatia.ru/upload/iblock/017/0170a5bc251fc6b518ace58306e5dcc3.png">
        <div style="display: flex; flex-direction: column;">
            <div class="logo-text" style="font-size: 18px"><%= t(:label_portfolios) %></div>
        </div>
    </div>
    <div style="display: flex; flex-direction: column;">
        <a style="margin-bottom: 10px" class="on-hover" href="#">Отзывы и замечания о работе сайта</a>
        <div class="socials" style="display: none">
            <a href="#" class="on-hover"><img src="https://egov-buryatia.ru/images/viber.png"></a>
            <a href="#" class="on-hover"><img src="https://egov-buryatia.ru/images/facebook.png"></a>
            <a href="#" class="on-hover"><img src="https://egov-buryatia.ru/images/youtube.png"></a>
            <a href="#" class="on-hover"><img src="https://egov-buryatia.ru/images/ok.png"></a>
            <a href="#" class="on-hover"><img src="https://egov-buryatia.ru/images/instagram.png"></a>
            <a href="#" class="on-hover"><img src="https://egov-buryatia.ru/images/vk.png"></a>
        </div>
    </div>
</div>
