<form name="add_target_form" class="form">
  <input type="text"
         class="wp-target--autocomplete ui-autocomplete--input" style="display: inline"
         [ngClass]="{ '-error': noResults && !initialSelection }"
         (keydown.enter)="handleEnterPressed($event)"
         (keydown.escape)="onEscapePressed.emit($event)"
         (blur)="onBlur.emit($event)"
         [attr.placeholder]="inputPlaceholder">
  <div class="ui-autocomplete--loading" style="display: none">
    <div class="loading-indicator -small">
      <div class="block-1"></div>
      <div class="block-2"></div>
      <div class="block-3"></div>
      <div class="block-4"></div>
      <div class="block-5"></div>
    </div>
  </div>
</form>
