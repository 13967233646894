<div *ngFor="let value of cell_value;let i = index">
    <div class="border-progress">
        <div class="progress" [title]="value + '%'">
          <span class="value" [ngStyle]="{'width': value > 100 ? '100%' : value +'%', 'background-color': value < 80 ? '#ee8888' : value < 100 ? '#eeee88' : '#88ee88'}">
          </span>
        </div>
    </div>
    <br *ngIf="i != cell_value.length-1">
</div>

