import {NgModule} from "@angular/core";
import {KPIFormComponent} from "core-app/features/kpi/form/kpi-form.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDividerModule} from "@angular/material/divider";
import {FormsModule} from "@angular/forms";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatPaginatorModule} from "@angular/material/paginator";
import {KpiListComponent} from "core-app/features/kpi/list/kpi-list.component";
import {KpiViewComponent} from "core-app/features/kpi/view/kpi-view.component";
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    FormsModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  providers: [],
  declarations: [
    KPIFormComponent,
    KpiListComponent,
    KpiViewComponent
  ],
  exports: [
    KPIFormComponent,
    KpiListComponent,
    KpiViewComponent
  ]
})
export class KpiModule {
}
