import {Component, ElementRef, OnInit, ViewChild, Input} from '@angular/core';
import {ChartOptions, ChartType, ChartDataset} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {I18nService} from "core-app/core/i18n/i18n.service";
import {DynamicBootstrapper} from "core-app/core/setup/globals/dynamic-bootstrapper";
import 'chartjs-plugin-labels';
import {HalResourceService} from "core-app/features/hal/services/hal-resource.service";
import {PathHelperService} from "core-app/core/path-helper/path-helper.service";

export const homescreenDiagramSelector = 'homescreen-diagram';

@Component({
  selector: homescreenDiagramSelector,
  templateUrl: './homescreen-diagram.html',
  styleUrls: ['./homescreen-diagram.sass']
})
export class HomescreenDiagramComponent implements OnInit {
  @Input('data') public data:any[];
  @Input('chartLabels') public chartLabels:any[];
  @Input('chartColors') public chartColors:any[];
  @Input('chartOptions') public chartOptions:ChartOptions;
  @Input('chartPlugins') public chartPlugins:[];
  @Input('chartType') public chartType:any;
  @Input('chartLegend') public chartLegend:any;
  @Input('label') public label:any;

  public plugin = {
    labels: {
      render: 'value',
      fontSize: 14,
      fontStyle: 'bold',
      fontColor: 'rgba(0,0,0,0)',
    },
  };

  public defaultChartOptions:ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: {
        ticks: {
          display: false
        },
        grid: {
          display: false,
          // drawBorder: false,
        }
      },
      yAxes: {
        ticks: {
          // min:0,
          // max:120,
          display: false
        },
        grid: {
          display: false,
          // drawBorder: false,
        }
      }
    },
// Only available if legend plugin is registered and enabled)
    // legend: {
    //   position: 'right',
    //   labels: {
    //     boxWidth: 15
    //   }
    // },
    // plugins: this.plugin,
  };
  public barChartOptions:ChartOptions;

  public barChartLabels:string[];
  public barChartType:ChartType = 'pie';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData:ChartDataset[] = [
    {
      data: [],
      label: '',
      backgroundColor: ['#00b050', '#ffc000', '#c00000', '#1f497d']
    }
  ];
  public selectedIndex: number | null = null;

  @ViewChild(BaseChartDirective) chart:BaseChartDirective;

  constructor(protected I18n:I18nService,
              readonly element:ElementRef,
              protected halResourceService:HalResourceService,
              protected pathHelper:PathHelperService) { }

  ngOnInit() {
    this.refresh();

  }

  ngOnChanges() {
    this.refresh();
  }

  public getData(): number[] {
    const list: number[] = new Array<number>();
    const data: number[] = this.barChartData[0].data as unknown as Array<number>;

    for (let i = 0; i < this.barChartLabels.length; i++) {
      const number: number = data[i];

      list.push(number);
    }

    return list;
  }

  public showPopup(itemIndex: number): void {
    this.selectedIndex = itemIndex;
  }

  public hidePopup(): void {
    this.selectedIndex = null;
  }

  protected refresh() {
    this.barChartType = this.chartType || this.barChartType; //default chart type
    this.barChartLabels = this.chartLabels || this.barChartLabels; //default chart labels
    this.barChartLegend = this.chartLegend ||  true;
    this.barChartPlugins = this.chartPlugins || [];
    this.barChartOptions = this.chartOptions || this.defaultChartOptions;
    this.barChartData = [
      {
        data: this.data || [], //default data set
        label: this.label || '', //default label
        backgroundColor: this.chartColors || ['#00b050',
          '#c00000',
          '#1f497d',
          '#ffc000'
        ] //default color set
      }
    ];
  }

  public refreshByMunicipality(raionId:number) {
/*    let barChartName = this.element.nativeElement.getAttribute('chart-name') || 0;
    this.halResourceService
      .get<DiagramHomescreenResource>(this.pathHelper.api.v3.diagrams.toString() + '/' + barChartName, {raionId: raionId})
      .toPromise()
      .then((resource:DiagramHomescreenResource) => {
        this.barChartData[0].data = resource.data;
        this.barChartData[0].label = resource.label;
        if (resource.label === 'visible') {
          this.element.nativeElement.parentElement.removeAttribute('style');
          this.element.nativeElement.parentElement.style.visibility = 'visible';
          this.element.nativeElement.parentElement.style.width = '350px';
        }
        if (resource.label === 'hidden') {
          this.element.nativeElement.parentElement.removeAttribute('style');
          this.element.nativeElement.parentElement.style.display = 'none';
        }
      });*/
  }
}

DynamicBootstrapper.register({ selector: homescreenDiagramSelector, cls: HomescreenDiagramComponent });
