import {BlueTableService} from "core-components/homescreen-blue-table/blue-table.service";
import {HalResource} from "core-app/features/hal/resources/hal-resource";
import {ProjectResource} from "core-app/features/hal/resources/project-resource";
import {CollectionResource} from "core-app/features/hal/resources/collection-resource";
import htmlString = JQuery.htmlString;
import {HomescreenProgressBarComponent} from "core-components/homescreen-progress-bar/homescreen-progress-bar.component";
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';

export class BlueTableMunicipalityService extends BlueTableService {
  protected columns:string[] = [
    I18n.t('js.label_blue_table_project'), I18n.t('js.label_blue_table_curator_rp'),
    I18n.t('js.label_blue_table_plan_due'), I18n.t('js.label_blue_table_future_work_packgage'),
    I18n.t('js.label_blue_table_prosroch'), I18n.t('js.label_blue_table_progress'),
    I18n.t('js.label_blue_table_kpi')
  ];
  private filter:string = '0';
  private data_local:any;
  public table_data:any = [];
  public configs:any = {
    id_field: 'id',
    parent_id_field: 'parentId',
    parent_display_field: 'homescreen_name',
    show_summary_row: false,
    css: { // Optional
      expand_class: 'icon-arrow-right2',
      collapse_class: 'icon-arrow-down1',
    },
    columns: [
      {
        name: 'homescreen_name',
        header: this.columns[0]
      },
      {
        name: 'homescreen_curator',
        header: this.columns[1]
      },
      {
        name: 'homescreen_due_date',
        header: this.columns[2]
      },
      {
        name: 'homescreen_future',
        header: this.columns[3]
      },
      {
        name: 'homescreen_risks',
        header: this.columns[4]
      },
      {
        name: 'homescreen_progress',
        header: this.columns[5],
        type: 'custom',
        component: HomescreenProgressBarComponent
      },
      {
        name: 'homescreen_kpi',
        header: this.columns[6]
      }
    ]
  };
  private national_project_titles:{ id:number, name:string }[];
  private national_projects:HalResource[];

  public getDataFromPage(i:number):Promise<any[]> {
    return new Promise((resolve) => {
      let data:any[] = [];
      let from = new Date();
      let to = new Date();
      to.setDate(to.getDate() + 14);
      this.national_projects.map((el:HalResource) => {
        if ((Number(el.id) === this.national_project_titles[i].id) || (el.parentId && el.parentId === this.national_project_titles[i].id)) {
          data.push({
            id: el.id + el.type,
            parentId: el.parentId + 'National' || 0,
            homescreen_name: el.name
          });
          if (this.data_local[Number(el.id)]) {
            this.data_local[Number(el.id)].map((project:ProjectResource) => {
              let progress = project.all_wps === 0 ? '0' : Number(project.ispolneno / project.all_wps * 100).toFixed(1).toString();
              let budget = Number(project.budget_fraction * 100).toFixed(1).toString();

              const curators_links = super.getCuratorsLinksFromProject(project);
              const rukovoditels_links = super.getRukovoditelsLinks(project);
              const homescreen_curator = `${curators_links}${curators_links === '' || rukovoditels_links === '' ? '' : '<br>' + rukovoditels_links}`;

              data.push({
                id: project.project_id + 'Project',
                parentId: !project.federal_id ? project.parentId + el.type : project.parentId + 'Federal',
                homescreen_name: '<a href="' + super.getBasePath() + '/projects/' + project.identifier + '">' + project.name + '</a>',
                homescreen_curator: homescreen_curator === '' ? I18n.t('js.project.unassigned') : homescreen_curator,
                homescreen_due_date: this.format(project.dueDate),
                homescreen_future: '<a href=\'' + super.getBasePath() + '/projects/' + project.identifier +
                  '/work_packages?query_id=3&query_props=%7B"c"%3A%5B"id"%2C"subject"%2C"type"%2C"status"%2C"assignee"%5D%2C"hl"%3A"none"%2C"hi"%3Afalse%2C"g"%3A""%2C"t"%3A""%2C"f"%3A%5B%7B"n"%3A"status"%2C"o"%3A"o"%2C"v"%3A%5B%5D%7D%2C%7B"n"%3A"type"%2C"o"%3A"%3D"%2C"v"%3A%5B"1"%5D%7D%2C%7B"n"%3A"dueDate"%2C"o"%3A"<>d"%2C"v"%3A%5B"' + from.toISOString().slice(0, 10) + '"%2C"' + to.toISOString().slice(0, 10) + '"%5D%7D%5D%2C"pa"%3A1%2C"pp"%3A20%7D\'>' + project.preds + '</a>',
                homescreen_risks: '<a href=\'' + super.getBasePath() + '/projects/' + project.identifier +
                  '/work_packages?query_id=5&query_props=%7B"c"%3A%5B"id"%2C"type"%2C"status"%2C"subject"%2C"startDate"%2C"dueDate"%5D%2C"tv"%3Atrue%2C"tzl"%3A"days"%2C"hl"%3A"none"%2C"hi"%3Afalse%2C"g"%3A""%2C"t"%3A"id%3Aasc"%2C"f"%3A%5B%7B"n"%3A"status"%2C"o"%3A"="%2C"v"%3A%5B"2"%5D%7D%2C%7B"n"%3A"type"%2C"o"%3A"%3D"%2C"v"%3A%5B"2"%5D%7D%2C%7B"n"%3A"dueDate"%2C"o"%3A"<t-"%2C"v"%3A%5B"0"%5D%7D%5D%2C"pa"%3A1%2C"pp"%3A20%7D\'>' + project.prosr + '</a>&nbsp;/&nbsp;' +
                  '<a href="' + super.getBasePath() + '/vkladka1/problems?id=' + project.project_id + '">' + project.riski + '</a>',
                homescreen_progress: [progress, budget],
                homescreen_kpi: '<a href="' + super.getBasePath() + '/vkladka1/kpi">Посмотреть</a>',
              });
            });
          }
        }
      });
      resolve(data);
    });
  }
  public getPages():number {
    return this.national_project_titles.length;
  }

  public initializeAndGetData():Promise<any[]> {
    this.filter = '0';
    return new Promise((resolve, reject) => {
      this.national_project_titles = [];
      this.halResourceService
        .get<CollectionResource<HalResource>>(this.apiV3Service.national_projects.path.toString())
        .toPromise()
        .then((resources:CollectionResource<HalResource>) => {
          this.national_projects = resources.elements;
          this.national_projects.map((el:HalResource) => {
            if (!el.parentId) {
              this.national_project_titles.push({id: Number(el.id), name: el.name});
            }
          });
          this.getDataWithFilter(this.filter).then(data => {
            resolve(data);
          }).catch((error) => {
            console.error(error);
            reject(new Error('Ошибка при получении данных с фильтром'));
          })
        }).catch((error) => {
        console.error(error);
        reject(new Error('Ошибка при инициализации данных муниципалитета'));
      });
    });
  }

  public getTdData(row:any, i:number):string {
    if (row._type === 'Project') {
      switch (i) {
        case 0: {
          return '<a href="' + super.getBasePath() + '/projects/' + row.identifier + '">' + row.name + '</a>';
          break;
        }
        case 1: {
          const curators_links = super.getCuratorsLinksFromProject(row);
          const rukovoditels_links = super.getRukovoditelsLinks(row);
          return `${curators_links}${curators_links === '' || rukovoditels_links === '' ? '' : '<br>' + rukovoditels_links}`;
        }
        case 2: {
          return this.format(row.dueDate);
          break;
        }
        case 3: {
          let from = new Date();
          let to = new Date();
          to.setDate(to.getDate() + 14);
          return '<a href=\'' + super.getBasePath() + '/projects/' + row.identifier +
            '/work_packages?query_id=3&query_props=%7B"c"%3A%5B"id"%2C"subject"%2C"type"%2C"status"%2C"assignee"%5D%2C"hl"%3A"none"%2C"hi"%3Afalse%2C"g"%3A""%2C"t"%3A""%2C"f"%3A%5B%7B"n"%3A"status"%2C"o"%3A"o"%2C"v"%3A%5B%5D%7D%2C%7B"n"%3A"type"%2C"o"%3A"%3D"%2C"v"%3A%5B"1"%5D%7D%2C%7B"n"%3A"dueDate"%2C"o"%3A"<>d"%2C"v"%3A%5B"' + from.toISOString().slice(0, 10) + '"%2C"' + to.toISOString().slice(0, 10) + '"%5D%7D%2C%7B"n"%3A"raion"%2C"o"%3A"="%2C"v"%3A%5B"' + this.filter + '"%5D%7D%5D%2C"pa"%3A1%2C"pp"%3A20%7D\'>' + row.preds + '</a>';
          break;
        }
        case 4: {
          return '<a href=\'' + super.getBasePath() + '/projects/' + row.identifier +
            '/work_packages?query_id=5&query_props=%7B"c"%3A%5B"id"%2C"type"%2C"status"%2C"subject"%2C"startDate"%2C"dueDate"%5D%2C"tv"%3Atrue%2C"tzl"%3A"days"%2C"hl"%3A"none"%2C"hi"%3Afalse%2C"g"%3A""%2C"t"%3A"id%3Aasc"%2C"f"%3A%5B%7B"n"%3A"status"%2C"o"%3A"="%2C"v"%3A%5B"2"%5D%7D%2C%7B"n"%3A"type"%2C"o"%3A"%3D"%2C"v"%3A%5B"2"%5D%7D%2C%7B"n"%3A"dueDate"%2C"o"%3A"<t-"%2C"v"%3A%5B"0"%5D%7D%2C%7B"n"%3A"raion"%2C"o"%3A"="%2C"v"%3A%5B"' + this.filter + '"%5D%7D%5D%2C"pa"%3A1%2C"pp"%3A20%7D\'>' + row.prosr + '</a>&nbsp;/&nbsp;' +
            '<a href="' + super.getBasePath() + '/vkladka1/problems?id=' + row.project_id + '">' + row.riski + '</a>';
          break;
        }
        case 5: {
          return row.all_wps === 0 ? '0' : Number(row.ispolneno / row.all_wps * 100).toFixed(1).toString();
          break;
        }
        case 6: {
          return '<a href="' + super.getBasePath() + '/vkladka1/kpi">Посмотреть</a>';
          break;
        }
        case 100: {
          return Number(row.budget_fraction * 100).toFixed(1).toString();
          break;
        }
      }
    } else {
      switch (i) {
        case 0: {
          return row.name;
          break;
        }
      }
    }
    return '';
  }

  public getTdClass(row:any, i:number):string {
    switch (i) {
      case 0: {
        if (row._type === 'Project') {
          return 'p30';
        }
        return row.parentId == null ? 'p10' : 'p20';
        break;
      }
      case 5: {
        if (row._type === 'Project') {
          return 'progressbar2';
        }
        break;
      }
    }
    return '';
  }

  public format(input:string):string {
    if (!input) {
      return '';
    }
    return input.slice(3, 5) + '.' + input.slice(0, 2) + '.' + input.slice(6, 10);
  }
  public progressbar(input:any):htmlString {
    let color = parseInt(input) < 30 ? '#ee8888' : parseInt(input) < 60 ? '#eeee88' : '#88ee88';
    return  '<div class="border-progress">' +
      '<div class="progress" title="' + input + '%' + '">' +
      '<span class="value" style="display:none;width:' + input + '%; background-color:' + color + '">' +
      '</span>' +
      '</div>' +
      '</div>';
  }
  public pagesToText(i:number):string {
    return this.national_project_titles[i].name;
  }

  public getDataWithFilter(param:string):Promise<any[]> {
    return new Promise((resolve, reject) => {
      let data:any[] = [];
      let data_local:any = {};
      let from = new Date();
      let to = new Date();
      to.setDate(to.getDate() + 14);
      this.filter = param;
      this.halResourceService
        .get<HalResource>(this.apiV3Service.work_package_stat_by_proj_view.path.toString(), {"raion": this.filter})
        .toPromise()
        .then((resource:HalResource) => {
          resource.$source.map((el:HalResource) => {
            if ((el.federal_id !== 0) || (el.federal_id === 0 && el.national_id === 0)) {
              data_local[el.federal_id] = data_local[el.federal_id] || [];
              data_local[el.federal_id].push(el);
            } else {
              data_local[el.national_id] = data_local[el.national_id] || [];
              data_local[el.national_id].push(el);
            }
          });
          this.national_projects.map((el:HalResource) => {
            if ((Number(el.id) === this.national_project_titles[0].id) || (el.parentId && el.parentId === this.national_project_titles[0].id)) {
              data.push({
                id: el.id + el.type,
                parentId: el.parentId + 'National' || 0,
                homescreen_name: el.name
              });
              if (data_local[Number(el.id)]) {
                data_local[Number(el.id)].map((project:ProjectResource) => {
                  let progress = project.all_wps === 0 ? '0' : Number(project.ispolneno / project.all_wps).toFixed(1).toString();
                  let budget = Number(project.budget_fraction).toFixed(1).toString();

                  const curators_links = super.getCuratorsLinksFromProject(project);
                  const rukovoditels_links = super.getRukovoditelsLinks(project);
                  const homescreen_curator = `${curators_links}${curators_links === '' || rukovoditels_links === '' ? '' : '<br>' + rukovoditels_links}`;

                  data.push({
                    id: project.project_id + 'Project',
                    parentId: !project.federal_id ? project.parentId + el.type : project.parentId + 'Federal',
                    homescreen_name: '<a href="' + super.getBasePath() + '/projects/' + project.identifier + '">' + project.name + '</a>',
                    homescreen_curator: homescreen_curator === '' ? I18n.t('js.project.unassigned') : homescreen_curator,
                    homescreen_due_date: this.format(project.dueDate),
                    homescreen_future: '<a href=\'' + super.getBasePath() + '/projects/' + project.identifier +
                      '/work_packages?query_id=3&query_props=%7B"c"%3A%5B"id"%2C"subject"%2C"type"%2C"status"%2C"assignee"%5D%2C"hl"%3A"none"%2C"hi"%3Afalse%2C"g"%3A""%2C"t"%3A""%2C"f"%3A%5B%7B"n"%3A"status"%2C"o"%3A"o"%2C"v"%3A%5B%5D%7D%2C%7B"n"%3A"type"%2C"o"%3A"%3D"%2C"v"%3A%5B"1"%5D%7D%2C%7B"n"%3A"dueDate"%2C"o"%3A"<>d"%2C"v"%3A%5B"' + from.toISOString().slice(0, 10) + '"%2C"' + to.toISOString().slice(0, 10) + '"%5D%7D%5D%2C"pa"%3A1%2C"pp"%3A20%7D\'>' + project.preds + '</a>',
                    homescreen_risks: '<a href=\'' + super.getBasePath() + '/projects/' + project.identifier +
                      '/work_packages?query_id=5&query_props=%7B"c"%3A%5B"id"%2C"type"%2C"status"%2C"subject"%2C"startDate"%2C"dueDate"%5D%2C"tv"%3Atrue%2C"tzl"%3A"days"%2C"hl"%3A"none"%2C"hi"%3Afalse%2C"g"%3A""%2C"t"%3A"id%3Aasc"%2C"f"%3A%5B%7B"n"%3A"status"%2C"o"%3A"="%2C"v"%3A%5B"2"%5D%7D%2C%7B"n"%3A"type"%2C"o"%3A"%3D"%2C"v"%3A%5B"2"%5D%7D%2C%7B"n"%3A"dueDate"%2C"o"%3A"<t-"%2C"v"%3A%5B"0"%5D%7D%5D%2C"pa"%3A1%2C"pp"%3A20%7D\'>' + project.prosr + '</a>&nbsp;/&nbsp;' +
                      '<a href="' + super.getBasePath() + '/vkladka1/problems?id=' + project.project_id + '">' + project.riski + '</a>',
                    homescreen_progress: [progress, budget],
                    homescreen_kpi: '<a href="' + super.getBasePath() + '/vkladka1/kpi">Посмотреть</a>',
                  });
                });
              }
            }
          });
          this.data_local = data_local;
          resolve(data);
        }).catch((error) => {
        console.error(error);
        reject(new Error('Ошибка при получении статуса рабочего пакета по проекту'));
      });
    });
  }
}
