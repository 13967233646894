<!-- Tab content -->
<div id="consolidated" class="tabcontent" style="display: block;">
    <h2>Оценка деятельности высшего должностного лица</h2>

    <homescreen-blue-table [template]="'performance'"></homescreen-blue-table>

    <div style="width: 900px; left: 50%; position: relative; margin-left: -450px;">
        <homescreen-performance-diagram></homescreen-performance-diagram>
    </div>
</div>
