import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { IDynamicFieldGroupConfig, IOPFormlyFieldSettings } from 'core-app/shared/components/dynamic-forms/typings';
import { UntilDestroyedMixin } from 'core-app/shared/helpers/angular/until-destroyed.mixin';
import { CurrentProjectService } from 'core-app/core/current-project/current-project.service';
import { PathHelperService } from 'core-app/core/path-helper/path-helper.service';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { FIELDS } from 'core-app/features/projects/form-helpers/form-attribute-groups';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent extends UntilDestroyedMixin implements OnInit {
  projectsPath:string;

  formMethod = 'patch';
  fieldGroups:IDynamicFieldGroupConfig[];

  text = {
    advancedSettingsLabel: this.I18n.t('js.forms.advanced_settings'),
    mainSettingsLabel: this.I18n.t('js.forms.main_settings')
  };
  dynamicFieldsSettingsPipe = this.fieldSettingsPipe.bind(this);

  hiddenFields = ['identifier', 'active'];

  constructor(
    private _pathHelperService:PathHelperService,
    private _$state:StateService,
    private _currentProjectService:CurrentProjectService,
    private I18n:I18nService,
  ) {
    super();
  }

  ngOnInit():void {
    this.projectsPath = this._currentProjectService.apiv3Path!;
    this.fieldGroups = [{
      name: this.text.mainSettingsLabel,
      fieldsFilter: (field) => FIELDS.includes(field.templateOptions?.property as string),
    },
      {
        name: this.text.advancedSettingsLabel,
        fieldsFilter: (field) => !FIELDS.includes(field.templateOptions?.property as string)
          && !this.isMeta(field.templateOptions?.property)
          && !(field.templateOptions?.required
            && !field.templateOptions.hasDefault
            && field.templateOptions.payloadValue == null),
      },];
  }

  private isFieldHidden(name:string|undefined) {
    return this.hiddenFields.includes(name || '');
  }

  private isMeta(property: string | undefined): boolean {
    return !!property && (property.startsWith('copy') || property === 'sendNotifications');
  }

  private fieldSettingsPipe(dynamicFieldsSettings:IOPFormlyFieldSettings[]):IOPFormlyFieldSettings[] {
    return dynamicFieldsSettings.map((field) => ({ ...field, hide: this.isFieldHidden(field.key) }));
  }
}
