import {Component, OnInit} from "@angular/core";
import {DynamicBootstrapper} from "core-app/core/setup/globals/dynamic-bootstrapper";
import {KPI} from "core-app/features/kpi/schema";
import {PathHelperService} from "core-app/core/path-helper/path-helper.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {MatTableDataSource} from "@angular/material/table";
import {PageEvent} from "@angular/material/paginator";
import { Paginator } from 'core-app/features/pages/pages.component';

@Component({
  selector: 'op-kpi-list',
  templateUrl: './kpi-list.component.html',
  styleUrls: ['./kpi-list.component.sass']
})
export class KpiListComponent implements OnInit {
  public kpis:KPI[];
  dataSource = new MatTableDataSource<KPI>();
  displayedColumns:string[] = ['name', 'query', 'is_deleted'];
  paginatorSettings = new Paginator();

  constructor(protected pathHelper:PathHelperService,
              protected httpClient:HttpClient) {
  }

  ngOnInit():void {
    this.loadKpis();
  }

  private loadKpis(size?:number, index?:number) {
    this.httpClient.get(
      this.pathHelper.javaApiPath.javaApiBasePath + `/kpis`, {
        params: new HttpParams()
          .set('size', size ? size.toString() : '20')
          .set('page', index ? index.toString() : '0')
      }).toPromise()
      .then((response:any) => {
        this.kpis = response._embedded.kpis;
        this.paginatorSettings = response.page;
        this.dataSource.data = this.kpis;
      })
      .catch((reason) => console.error(reason));
  }

  public pageChanged(event:PageEvent) {
    this.loadKpis(event.pageSize, event.pageIndex);
  }

  deleteKPI(kpi:KPI) {
    this.httpClient.post(
      this.pathHelper.javaUrlPath + `/kpi/delete`, {}, {
        params: new HttpParams()
          .set('kpiId', kpi.id!.toString())
      }).toPromise()
      .then((response:any) => {
        console.log(this.kpis.findIndex((item) => item.id === kpi.id));
        this.kpis.splice(this.kpis.findIndex((item) => item.id === kpi.id), 1);
        this.dataSource.data = this.kpis;
      })
      .catch((reason) => console.error(reason));
  }
}
DynamicBootstrapper.register({selector: 'op-kpi-list', cls: KpiListComponent});
