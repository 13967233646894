// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++

import { DisplayField } from 'core-app/shared/components/fields/display/display-field.module';
import {HalResource} from "core-app/features/hal/resources/hal-resource";
// import {Highlighting} from "core-components/wp-fast-table/builders/highlighting/highlighting.functions";
// import {DynamicBootstrapper} from "core-app/globals/dynamic-bootstrapper";

export class HrefDisplayField extends DisplayField {
  public render(element:HTMLElement, displayText:string):void {
    let a = document.createElement('a');
    a.innerHTML = displayText;
    a.href = displayText;
    a.target = '_blank';
    let i = document.createElement('i');
    i.className = 'button--icon icon-edit';
    i.setAttribute('style', 'padding-left: 5px;');
    element.appendChild(a);
    element.appendChild(i);
  }
}
