import {Component} from "@angular/core";
import {I18nService} from "core-app/core/i18n/i18n.service";
import {HalResourceService} from "core-app/features/hal/services/hal-resource.service";
import {PathHelperService} from "core-app/core/path-helper/path-helper.service";
import {HalResource} from "core-app/features/hal/resources/hal-resource";
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';


@Component({
  templateUrl: './indicator-tab.html'
})
export class IndicatorTabComponent {
  public chartsData:any[];
  protected readonly appBasePath:string;
  constructor(protected halResourceService:HalResourceService,
              protected pathHelper:PathHelperService,
              readonly apiV3Service:ApiV3Service,
              readonly I18n:I18nService) {
    this.appBasePath = window.appBasePath ? window.appBasePath : '';
  }
  ngOnInit() {
    this.getChartsData();
  }
  private getChartsData() {
    this.halResourceService
      .get<HalResource>(`${this.apiV3Service.diagrams.path.toString()}/municipality`)
      .toPromise()
      .then((resource:HalResource) => {
        this.chartsData = resource.nationalProjects;
      }).catch((error) => {
        console.error(error.message);
    });
  }
}
