<h2>Исполнение бюджета</h2>

<div ng-app="OpenProjectLegacy" class="ng-scope">
    <div id="invisible-grid" class="widget-boxes project-overview">
        <div class="grid-block" style="overflow-x: scroll;">
            <div style="flex:auto;display: flex;align-items: center;justify-content: center;">
                <div style="max-width:350px">
                    <p style="text-align: center;font-weight: bold;margin: -2px;">Федеральный бюджет</p>
                    <homescreen-diagram [data]="this.budgetFederalChartData" [label]='this.budgetFederalChartLabel'
                                        [chartLabels]='["Факт. исп.","Риски исп.","Остаток"]'
                                        [chartColors]='["#00b050", "#ffc000", "#c00000"]'>
                    </homescreen-diagram>
                </div>
            </div>
            <div style="flex:auto;display: flex;align-items: center;justify-content: center;">
                <div style="max-width:350px">
                    <p style="text-align: center;font-weight: bold;margin: -2px;">Региональный бюджет</p>
                    <homescreen-diagram [data]="this.budgetRegionalChartData" [label]='this.budgetRegionalChartLabel'
                                        [chartLabels]='["Факт. исп.","Риски исп.","Остаток"]'
                                        [chartColors]='["#00b050", "#ffc000", "#c00000"]'>
                    </homescreen-diagram>
                </div>
            </div>
            <div style="flex:auto;display: flex;align-items: center;justify-content: center;">
                <div style="max-width:350px">
                    <p style="text-align: center;font-weight: bold;margin: -2px;">Внебюджетные источники</p>
                    <homescreen-diagram [data]="this.budgetOtherChartData" [label]='this.budgetOtherChartLabel'
                                        [chartLabels]='["Факт. исп.","Риски исп.","Остаток"]'
                                        [chartColors]='["#00b050", "#ffc000", "#c00000"]'>
                    </homescreen-diagram>
                    <!--                <homescreen-diagram chart-name='other_budget' chart-labels='["Факт. исп.","Риски исп.","Остаток"]' chart-colors='["#00b050", "#ffc000", "#c00000"]'>-->
                    <!--                </homescreen-diagram>-->
                </div>
            </div>
        </div>
    </div>
    <homescreen-blue-table [template]="'budget'"></homescreen-blue-table>
</div>

