import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ChartOptions, ChartType, ChartDataset} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {I18nService} from "core-app/core/i18n/i18n.service";
import {DynamicBootstrapper} from "core-app/core/setup/globals/dynamic-bootstrapper";
import 'chartjs-plugin-labels';
import {HalResourceService} from "core-app/features/hal/services/hal-resource.service";
import {PathHelperService} from "core-app/core/path-helper/path-helper.service";
import {DiagramHomescreenResource} from "core-app/features/hal/resources/diagram-homescreen-resource";
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';

export const homescreenPerformanceDiagramSelector = 'homescreen-performance-diagram';

@Component({
  selector: homescreenPerformanceDiagramSelector,
  templateUrl: './homescreen-performance-diagram.html'
})
export class HomescreenPerformanceDiagramComponent {
  public zagolovok:string;
  public barChartOptions:ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          // drawBorder: false,
        }
      },
      yAxes: {
        ticks: {
          // beginAtZero: true,
          display: false
        },
        grid: {
          display: false,
          // drawBorder: false,
        }
      }
    },
    // legend: {
    //   display: true,
    //   position: 'bottom',
    //   labels: {
    //     boxWidth: 15
    //   }
    // },
    // plugins: {
    //   labels: {
    //     render: 'value',
    //     fontSize: 14,
    //     fontStyle: 'bold',
    //     fontColor: '#000',
    //   }
    // },
  };

  public barChartLabels:string[] = ['Показатель'];
  public barChartType:ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData:ChartDataset[] = [
    {data:[0], label: "1.", backgroundColor: '#00b050', hoverBackgroundColor: '#00b050'},
    {data:[0], label: "2.", backgroundColor: '#ffc000', hoverBackgroundColor: '#ffc000'},
    {data:[0], label: "3.", backgroundColor: '#c00000', hoverBackgroundColor: '#c00000'},
    {data:[0], label: "4.", backgroundColor: '#1f497d', hoverBackgroundColor: '#1f497d'}
  ];

  @ViewChild(BaseChartDirective) chart:BaseChartDirective;

  constructor(protected I18n:I18nService,
              readonly element:ElementRef,
              protected halResourceService:HalResourceService,
              readonly apiV3Service:ApiV3Service,
              protected pathHelper:PathHelperService) { }

  public refresh() {
    let barChartName = this.element.nativeElement.getAttribute('performance-id') || 0;
    this.halResourceService
      .get<DiagramHomescreenResource>(this.apiV3Service.diagrams.path.toString() + '/head_performance', {performance: barChartName})
      .toPromise()
      .then((resource:DiagramHomescreenResource) => {
        let maxim:number | undefined = _.max(resource.data);
        //! TODO: добавлены "non-null assertion" операторы, проверить работоспособность
        if (maxim && this.chart.options!.scales && this.chart.options!.scales.yAxes) {
          let maxValue = Number((maxim * 2).toFixed(0));
          //! TODO: временно убрано обновление параметров диаграммы, т.к. есть расхождения в синтаксисе
          // this.chart.options.scales.yAxes[0] = {
          //   ticks: {
          //     max: maxValue,
          //     beginAtZero: true,
          //     display: false
          //   },
          //   gridLines: {
          //     display: true,
          //     drawBorder: false,
          //   }
          // };
          this.chart.chart!.update();
        }
        this.barChartData = [];
        this.barChartData.push({data:[resource.data[0]], label: "1.", backgroundColor: '#00b050', hoverBackgroundColor: '#00b050'});
        this.barChartData.push({data:[resource.data[1]], label: "2.", backgroundColor: '#ffc000', hoverBackgroundColor: '#ffc000'});
        this.barChartData.push({data:[resource.data[2]], label: "3.", backgroundColor: '#c00000', hoverBackgroundColor: '#c00000'});
        this.barChartData.push({data:[resource.data[3]], label: "4.", backgroundColor: '#1f497d', hoverBackgroundColor: '#1f497d'});
        this.zagolovok = resource.label;
      });
    /*this.barChartData = JSON.parse(this.element.nativeElement.getAttribute('chart-data'));
    this.barChartData[0].backgroundColor = JSON.parse(this.element.nativeElement.getAttribute('chart-colors')) || ['#00b050', '#ffc000', '#c00000', '#1f497d'];
    */
  }

  public hideMe() {
    if (!this.zagolovok) {
      return {'visibility': 'hidden'};
    }
    return {};
  }
}

DynamicBootstrapper.register({ selector: homescreenPerformanceDiagramSelector, cls: HomescreenPerformanceDiagramComponent });
