<div style="flex-grow: 1; display: flex; flex-direction: column" class="form-group">
        <h2>{{position.name}}</h2>

<div style="position: relative; top: 35px">
<div style="display:flex">
        <span style="width: 18%">Наименование *</span>
        <div style="max-width: 80%;"><input class="form--text-field" [style.width.px]="(position?.name?.length+1)*8" title="{{position.name}}" [(ngModel)]="position.name" name="name" style="max-width: 95%; min-width: 200px; height: 160%" matInput></div>
</div>
<div style="display:flex; position: relative; top: 25px">
        <span style="width: 18%">Утверждено</span>

        <div style="position: relative; top: 2px"><mat-checkbox color="primary" [(ngModel)]="position.isApprove"></mat-checkbox></div>
</div >
        <div style="display:flex">
        <button style="position: relative; top: 45px" name="button" type="submit" class="-highlight -with-icon icon-checkmark button" (click)="savePosition()">Сохранить</button>

        <div style="position: relative; top: 45px">
                <a class="button" rel="nofollow" style="height: 37px" data-method="delete" href="/admin/positions/{{position.id}}">
                        <span class="button--text" style="position: relative; top: 2px">Удалить</span>
                </a>
        </div>
        </div>
</div>

</div>
