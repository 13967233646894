import {HalResourceService} from "core-app/features/hal/services/hal-resource.service";
import {PathHelperService} from "core-app/core/path-helper/path-helper.service";
import {StateService} from "@uirouter/core";
import {ProjectResource} from "core-app/features/hal/resources/project-resource";
import {Injectable} from "@angular/core";
import {ApiV3Service} from "core-app/core/apiv3/api-v3.service";

@Injectable()
export abstract class BlueTableService {
  protected readonly appBasePath:string;
  protected readonly nameRBProject:string =  I18n.t('js.project_republic');
  protected readonly idRBProject:string = '0National';
  protected columns:string[] = [];
  protected pages:number = 0;
  protected configs:any;
  protected table_data:any;
  protected data:any;
  constructor(
    protected halResourceService:HalResourceService,
    protected pathHelper:PathHelperService,
    readonly apiV3Service:ApiV3Service,
    readonly $state:StateService) {
    this.appBasePath = window.appBasePath ? window.appBasePath : '';
  }
  public initializeAndGetData():Promise<any[]> {
    return Promise.resolve([]);
  }
  public getDataFromPage(i:number):Promise<any[]> {
    return Promise.resolve([]);
  }
  public getDataWithFilter(param:string):Promise<any[]>  {
    return Promise.resolve([]);
  }
  public getColumns():string[] {
    return this.columns;
  }
  public getPages():number {
    return this.pages;
  }
  public getTdData(row:any, i:number):string {
    return '';
  }
  public getTdClass(row:any, i:number):string {
    return '';
  }
  public getTrClass(row:any):string {
    return '';
  }
  public getBasePath():string {
    return this.appBasePath;
  }
  public getConfigs():any {
    return this.configs;
  }
  public getDatas():any {
    return this.table_data;
  }
  public pagesToText(i:number):string {
    return String(i + 1);
  }
  public getNode(id:any, parentId:any, name:any) {
    return {
      id: id,
      parentId: parentId,
      homescreen_name: name
    };
  }
  public getProjectPath(row:any) {
    return '<a href="' + this.getBasePath() + '/projects/' + row.identifier + '">' + row.name + '</a>';
  }
  public getNational(el:any = null) {
    return this.getNode(el ? el.id + el.type : this.idRBProject, el ? el.parentId + 'National' || 0 : '0', el ? el.name : this.nameRBProject);
  }
  public getProject(row:any, type:any) {
    return this.getNode(row.project_id + 'Project', row.parentId + type, this.getProjectPath(row));
  }

  public getCuratorsLinksFromProject(project:ProjectResource):string {
    if (Array.isArray(project.curator)) {
      return project.curator
        .map((curator:{ id:string, fio:string }) => `<a href="${this.getBasePath()}/users/${curator.id}">${curator.fio}</a>`)
        .join('<br>');
    } else if (project.curator) {
      return `<a href="${this.getBasePath()}/users/${project.curator.id}">${project.curator.fio}</a>`;
    }
    return '';
  }

  public getRukovoditelsLinks(project:ProjectResource):string {
    if (Array.isArray(project.rukovoditel)) {
      return project.rukovoditel
        .map((rukovoditel:{ id:string, fio:string }) => `<a href="${this.getBasePath()}/users/${rukovoditel.id}">${rukovoditel.fio}</a>`)
        .join('<br>');
    } else if (project.rukovoditel) {
      return `<a href="${this.getBasePath()}/users/${project.rukovoditel.id}">${project.rukovoditel.fio}</a>`;
    }
    return '';
  }
}
