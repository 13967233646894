import {BlueTableService} from "core-components/homescreen-blue-table/blue-table.service";
import {HalResource} from "core-app/features/hal/resources/hal-resource";
import {CollectionResource} from "core-app/features/hal/resources/collection-resource";
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';

export class BlueTableDiscussService extends BlueTableService {
  protected columns:string[] = [
    I18n.t('js.label_blue_table_project'), I18n.t('js.label_blue_table_curator_rp'),
    I18n.t('js.label_blue_table_theme'), I18n.t('js.label_blue_table_date_last_message'),
  ];
  public table_data:any = [];
  public configs:any = {
    id_field: 'id',
    parent_id_field: 'parentId',
    parent_display_field: 'homescreen_name',
    show_summary_row: false,
    css: { // Optional
      expand_class: 'icon-arrow-right2',
      collapse_class: 'icon-arrow-down1',
    },
    columns: [
      {
        name: 'homescreen_name',
        header: this.columns[0]
      },
      {
        name: 'homescreen_curator',
        header: this.columns[1]
      },
      {
        name: 'homescreen_subject',
        header: this.columns[2]
      },
      {
        name: 'homescreen_last',
        header: this.columns[3]
      }
    ]
  };
  private national_project_titles:{ id:number, name:string }[];
  private national_projects:HalResource[];

  public initializeAndGetData():Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.national_project_titles = [];
      this.halResourceService
        .get<CollectionResource<HalResource>>(this.apiV3Service.national_projects.path.toString())
        .toPromise()
        .then((resources:CollectionResource<HalResource>) => {
          this.national_projects = resources.elements;
          this.national_projects.map((el:HalResource) => {
            if (!el.parentId) {
              this.national_project_titles.push({id: Number(el.id), name: el.name});
            }
          });
          this.getDataFromPage(0).then(data => {
            resolve(data);
          }).catch((error) => {
            console.error(error);
            reject(new Error('Ошибка при получении данных со страницы'));
          });
        }).catch((error) => {
        console.error(error);
        reject(new Error('Ошибка при инициализации данных обсуждения'));
      });
    });
  }

  //! TODO: раскомментировать или убрать окончательно (уточнить что делать с убранными topics (связано с удалением boards))
  // public getDataFromPage(i:number):Promise<any[]> {
  //   return new Promise((resolve, reject) => {
  //     i += 1;
  //     let data:any[] = [];
  //     let data_local:any = {};
  //     this.halResourceService
  //       .get<CollectionResource<HalResource>>(this.apiV3Service.topics.path.toString(), {offset: i})
  //       .toPromise()
  //       .then((resources:CollectionResource<HalResource>) => {
  //         let total:number = resources.total; //всего ex 29
  //         let pageSize:number = resources.pageSize;
  //         let remainder = total % pageSize;
  //         if (total === 0) {
  //           data.push({
  //             id: 1,
  //             parentId: 0,
  //             homescreen_name: 'Нет данных'
  //           });
  //         }
  //         this.pages = (total - remainder) / pageSize;
  //         if (remainder !== 0) {
  //           this.pages++;
  //         }
  //         resources.elements.map((el:HalResource) => {
  //           const curators_links = super.getCuratorsLinksFromProject(el.project);
  //           const rukovoditels_links = super.getRukovoditelsLinks(el.project);
  //           const homescreen_curator = `${curators_links}${curators_links === '' || rukovoditels_links === '' ? '' : '<br>' + rukovoditels_links}`;
  //
  //           data.push({
  //             id: el.id + 'Topic',
  //             parentId: el.board.id + 'Board',
  //             homescreen_name: '<a href="' + super.getBasePath() + '/projects/' + el.project.identifier + '">' + el.project.name + '</a>',
  //             homescreen_curator: homescreen_curator === '' ? I18n.t('js.project.unassigned') : homescreen_curator,
  //             homescreen_subject: el.subject,
  //             homescreen_last: this.format(el.updatedOn)
  //           });
  //         });
  //         resolve(data);
  //       }).catch((error) => {
  //       console.error(error);
  //       reject(new Error('Ошибка при получении тем'));
  //     });
  //   });
  // }

  public getTdData(row:any, i:number):string {
    if (row._type === 'Message') {
      switch (i) {
        case 0: {
          return '<a href="' + super.getBasePath() + '/projects/' + row.project.identifier + '">' + row.project.name + '</a>';
          break;
        }
        case 1: {
          const curators_links = super.getCuratorsLinksFromProject(row.project);
          const rukovoditels_links = super.getRukovoditelsLinks(row.project);

          return `${curators_links}${curators_links === '' || rukovoditels_links === '' ? '' : '<br>' + rukovoditels_links}`;
        }
        case 2: {
          return row.subject;
          break;
        }
        case 3: {
          return this.format(row.updatedOn);
          break;
        }
      }
    }
    if (row._type === 'NationalProject') {
      switch (i) {
        case 0: {
          return row.name;
          break;
        }
      }
    }
    return '';
  }

  public getTdClass(row:any, i:number):string {
    switch (i) {
      case 0: {
        if (row._type === 'Message') {
          return 'p30';
        }
        return row.parentId == null ? 'p10' : 'p20';
        break;
      }
    }
    return '';
  }

  public format(input:string):string {
    return input.slice(8, 10) + '.' + input.slice(5, 7) + '.' + input.slice(0, 4);
  }
}
