<ng-select
  [items]="this.typesProgram"
  [formControl]="formControl"
  [formlyAttributes]="field"
  bindLabel="name"
  bindValue="isProgram"
  [searchable]="true"
  [virtualScroll]="true"
  [clearable]="false"
  [clearOnBackspace]="false"
  [clearSearchOnAdd]="false"
  [hideSelected]="true"
>
  <ng-template ng-label-tmp let-item="item">
    <span>{{item.name}}</span>
  </ng-template>
</ng-select>
