import {Component, OnInit, ViewChild} from "@angular/core";
import {I18nService} from "core-app/core/i18n/i18n.service";
import {ChartDataset, ChartOptions, ScatterDataPoint, ChartType} from "chart.js";
import {BaseChartDirective} from "ng2-charts";
// import {BaseChartDirective, Color} from "ng2-charts";
// import { Colors } from 'chart.js';  //!TODO: work around this
import {TimezoneService} from "core-app/core/datetime/timezone.service";
import {HalResourceService} from "core-app/features/hal/services/hal-resource.service";
import {PathHelperService} from "core-app/core/path-helper/path-helper.service";
import {CollectionResource} from "core-app/features/hal/resources/collection-resource";
import {ProjectResource} from "core-app/features/hal/resources/project-resource";
import {HalResource} from "core-app/features/hal/resources/hal-resource";
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';

export const bubbleDiagramSelector = 'bubble-diagram';

export interface ValueOption {
  name:string;
  id:string;
}

@Component({
  selector: bubbleDiagramSelector,
  templateUrl: './bubble-diagram.html'
})
export class BubbleDiagramComponent implements OnInit {
  public bubbleChartOptions:ChartOptions = {
    responsive: true,
    scales:{
      yAxes: {
        ticks: {
          // min: 0,
          // max: 2,
          display: false
        },
        grid: {
          display: false,
          // drawBorder: false,
        }
      },
      xAxes: {
        ticks: {
          // min: 0,
          // max: 4,
          display: false
        },
        grid: {
          display: false,
          // drawBorder: false,
        }
      }
      // yAxes: [{
      //   ticks: {
      //     min: 0,
      //     max: 2,
      //     display: false
      //   },
      //   gridLines: {
      //     display: false,
      //     drawBorder: false,
      //   }
      // }],
      // xAxes: [{
      //   ticks: {
      //     min: 0,
      //     max: 4,
      //     display: false
      //   },
      //   gridLines: {
      //     display: false,
      //     drawBorder: false,
      //   }
      // }]
    },
// Only available if legend plugin is registered and enabled)
    // legend: {
    //   position: 'top',
    //   labels: {
    //     boxWidth: 15
    //   }
    // },
    // tooltips: {
    //   callbacks: {
    //     label: function (t, d) {
    //       if (d.labels) {
    //         return d.labels[t.index || 0];
    //       } else {
    //         return '';
    //       }
    //     }
    //   }
    // }
  };
  // public bubbleChartColors:Color[] = [
  //   {
  //     backgroundColor: [
  //       'red',
  //       'green',
  //       'blue',
  //       'purple',
  //     ]
  //   }
  // ];
  public bubbleChartLabels:string[] = [];
  public bubbleChartType:ChartType = 'bubble';
  public bubbleChartLegend = true;
  public bubbleChartPlugins = [];
  public bubbleChartData:ChartDataset[] = [{data: [], label: 'Объем инвестиций', backgroundColor: ["red", "green", "blue", 'purple',]}];
  public projectSelect:ValueOption | undefined;
  public projectSelectOptions:ValueOption[] = [];

  @ViewChild(BaseChartDirective) chart:BaseChartDirective;

  constructor(readonly timezoneService:TimezoneService,
              protected I18n:I18nService,
              protected halResourceService:HalResourceService,
              readonly apiV3Service:ApiV3Service,
              protected pathHelper:PathHelperService) { }

  ngOnInit() {
    this.getProjects().then((resources:CollectionResource<ProjectResource>) => {
      let els = resources.elements;
      this.projectSelectOptions = els.map(el => {
        return {name: el.name, id: el.getId()};
      });
      this.projectSelectOptions.unshift({name: 'Все проекты', id: '0'});
      this.projectSelect = this.projectSelectOptions[0];
    });
    this.halResourceService
      .get<HalResource>(this.apiV3Service.diagrams.path.toString() + '/bubble')
      .toPromise()
      .then((resource:HalResource) => {
        let labels:string[] = [];
        resource.label.map((label:string) => {
          labels.push(label);
        });
        let smalldata:ScatterDataPoint[] = [];
        let colors:string[] = [];
        resource.data.map((array:any) => {
          // smalldata.push({x: array.x, y: array.y, r: array.r});  //! TODO: проверить как использовался массив r
          smalldata.push({x: array.x, y: array.y});
          colors.push("#" + ((array.id % 10) * 123456 + ((100000 - array.id) % 10) * 654321).toString(16).slice(-6));
        });
        this.bubbleChartLabels = labels;
        this.bubbleChartData[0].data = smalldata;
        // this.bubbleChartColors[0].backgroundColor = colors;
      });
  }

  public filterChart() {
    this.halResourceService
      .get<HalResource>(this.apiV3Service.diagrams.path.toString() + '/bubble', {project: this.projectSelect ? this.projectSelect.id : 0 })
      .toPromise()
      .then((resource:HalResource) => {
        let labels:string[] = [];
        resource.label.map((label:string) => {
          labels.push(label);
        });
        let smalldata:ScatterDataPoint[] = [];
        let colors:string[] = [];
        resource.data.map((array:any) => {
          // smalldata.push({x: array.x, y: array.y, r: array.r});  //! TODO: проверить как использовался массив r
          smalldata.push({x: array.x, y: array.y});
          colors.push("#" + ((array.id % 10) * 123456 + ((100000 - array.id) % 10) * 654321).toString(16).slice(-6));
        });
        this.bubbleChartLabels = labels;
        this.bubbleChartData[0].data = smalldata;
        // this.bubbleChartColors[0].backgroundColor = colors;
      });
  }

  private getProjects():Promise<CollectionResource<ProjectResource>> {
    return this.halResourceService
      .get<CollectionResource<ProjectResource>>(this.apiV3Service.projects.path.toString())
      .toPromise() as Promise<CollectionResource<ProjectResource>>;
  }
}
