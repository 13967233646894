<div class="homescreen-container">
    <div class="chart">
    <!--<button class="changeChart" type="button" (click)="refresh()" style="display: none"></button>-->
    <canvas id="chart" baseChart height="170px"
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [type]="barChartType">
    </canvas>
    </div>
    <div class="chart-info">
        <ul class="chart-info-list">
            <li *ngFor='let item of getData(); let i = index' class="chart-info-item">
                <b (mouseenter)='showPopup(i)' (mouseleave)='hidePopup()' [class.active]="selectedIndex === i">{{ item }}</b>
                <div *ngIf='selectedIndex === i' class="info-item-popup">
                    {{ item }}
                </div>
            </li>
        </ul>
    </div>
</div>
