<div class="ng-autocomplete" style="width: 100%;border: 2px solid #1C6EA4;display: inline-flex;margin: 10px 0;">
    <ng-autocomplete #autocomplete
                     [data]="data_autocomplete"
                     [searchKeyword]="keyword"
                     [isLoading]="check_load()"
                     [disabled]="check_load()"
                     placeHolder="Все районы..."
                     [initialValue]="data_autocomplete[0]"
                     (selected)="selectEvent($event)"
                     (inputCleared)="selectEvent({id: '0', name: 'Все районы'})"
                     [itemTemplate]="itemTemplate"
                     [notFoundTemplate]="notFoundTemplate"
                     debounceTime="1000"
                     notFoundText="Не найдено">
    </ng-autocomplete>
    <button class="icon-search" style="color: white;width: 8%;background: #1C6EA4;border: 1px #1C6EA4"></button>
    <ng-template #itemTemplate let-item >
        <a [innerHTML]="item.name" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis; padding: 5px 5px;"></a>
    </ng-template>
    <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
    </ng-template>
</div>

<div ng-app="OpenProjectLegacy" class="ng-scope">
    <div id="invisible-grid" class="widget-boxes project-overview">
        <div class="grid-block" style="overflow-x: scroll;">
            <div style="flex:auto;display: flex;align-items: center;justify-content: center;"
                 *ngFor="let chartData of chartsData; let i = index">
                <div style="max-width:350px">
                    <p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: center;font-weight: bold;margin: -2px 0; height: 50px"
                       [title]="chartData.name">{{chartData.name}}</p>
                    <homescreen-diagram [data]="chartData.data" [label]='chartData.label'
                                        [chartLabels]='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]'>
                    </homescreen-diagram>
                </div>
            </div>
        </div>
        <!--        <div class="grid-block" style="overflow-x: scroll;">
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_zdravoohr')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='zdravoohranenie' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_obraz')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='obrazovanie' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_demogr')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='demografia' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_cultur')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='cultura' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_avtodor')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='avtodorogi' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_gorsreda')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='gorsreda' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_ekol')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='ekologia' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_nauka')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='nauka' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px" title="{{this.I18n.t('js.national_project_msp')}}">{{this.I18n.t('js.national_project_msp_short')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='msp' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_digital')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='digital' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_trud')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='trud' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.national_project_export')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='export' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                    <div style="visibility:hidden;">
                        <p style="text-align: center;font-weight: bold;margin: -2px; height: 50px">{{this.I18n.t('js.project_republic')}}</p>
                        <homescreen-diagram [raionId]="selectedOption.$href" chart-name='republic' chart-labels='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]' chart-colors='["#00b050", "#ffc000", "#c00000", "#1f497d"]'>
                        </homescreen-diagram>
                    </div>
                </div>-->
    </div>
    <homescreen-blue-table [template]="'municipality'"></homescreen-blue-table>
    <br/>
    <div style="display: inline-flex; width: 100%;">
        <div style="width: 25%">
            <table border="0" class="coloredTable">
                <thead (click)="changeUpcomingTasksVisibility()">
                <tr>
                    <th class="colored-col-green" colspan="3">Предстоящие мероприятия: страница {{upcomingTasksPage}}</th>
                </tr>
                </thead>
                <tbody [hidden]="upcomingTasksVisibility">
                <tr *ngFor="let item of upcomingTasksData; let i = index">
                    <td [ngClass]="getGreenClass(i)"
                        [textContent]="item.number"></td>
                    <td [ngClass]="getGreenClass(i)">
                        <a href="{{this.getBasePath()}}/projects/{{item.projectId}}/work_packages/{{item.id}}/activity?"
                           [textContent]="shorten(item.subject)" [title]="item.subject"></a>
                    </td>
                    <td [ngClass]="getGreenClass(i)">
                        <a href="{{this.getBasePath()}}/projects/{{item.projectId}}"
                           [textContent]="shorten(item.project)" [title]="item.project"></a>
                    </td>
                </tr>
                <tr *ngIf="loadingUpcomingTasks">
                    <td [ngClass]="getGreenClass(0)" colspan="3">Загрузка...</td>
                </tr>
                <tr *ngIf="!loadingUpcomingTasks && upcomingTasksCount === 0">
                    <td [ngClass]="getGreenClass(0)" colspan="3">Нет данных</td>
                </tr>
                </tbody>
            </table>
            <pagination-footer  *ngIf="upcomingTasksPages > 1"
                                [hidden]="upcomingTasksVisibility"
                                [pages]="upcomingTasksPages"
                                (changePageEvent)="loadUpcomingTasksByPage($event)">

            </pagination-footer>
        </div>
        <div style="width: 25%">
            <table border="0" class="coloredTable">
                <thead (click)="changeDueMilestoneVisibility()">
                <tr>
                    <th class="colored-col-red" colspan="3">Просроченные КТ: страница {{dueMilestonePage}}</th>
                </tr>
                </thead>
                <tbody [hidden]="dueMilestoneVisibility">
                <tr *ngFor="let item of dueMilestoneData; let i = index">
                    <td [ngClass]="getRedClass(i)"
                        [textContent]="item.number"></td>
                    <td [ngClass]="getRedClass(i)">
                        <a href="{{this.getBasePath()}}/projects/{{item.projectId}}/work_packages/{{item.id}}/activity?"
                           [textContent]="shorten(item.subject)" [title]="item.subject"></a>
                    </td>
                    <td [ngClass]="getRedClass(i)">
                        <a href="{{this.getBasePath()}}/projects/{{item.projectId}}"
                           [textContent]="shorten(item.project)" [title]="item.project"></a>
                    </td>
                </tr>
                <tr *ngIf="loadingDueMilestones">
                    <td [ngClass]="getRedClass(0)" colspan="3">Загрузка...</td>
                </tr>
                <tr *ngIf="!loadingDueMilestones && dueMilestoneCount === 0">
                    <td [ngClass]="getRedClass(0)" colspan="3">Нет данных</td>
                </tr>
                </tbody>
            </table>
            <pagination-footer  *ngIf="dueMilestonePages > 1"
                                [hidden]="dueMilestoneVisibility"
                                [pages]="dueMilestonePages"
                                (changePageEvent)="loadDueMilestonesByPage($event)">

            </pagination-footer>
        </div>
        <div style="width: 25%">
            <table border="0" class="coloredTable">
                <thead (click)="changeProblemVisibility()">
                <tr>
                    <th class="colored-col-orange" colspan="3">Проблемы: страница {{problemPage}}</th>
                </tr>
                </thead>
                <tbody [hidden]="problemVisibility">
                <tr *ngFor="let item of problemData; let i = index">
                    <td [ngClass]="getOrangeClass(i)"
                        [textContent]="item.number"></td>
                    <td [ngClass]="getOrangeClass(i)">
                        <a href="{{this.getBasePath()}}/work_packages/{{item.wpid}}/problems"
                           [textContent]="shorten(item.risk)" [title]="item.risk"></a>
                    </td>
                    <td [ngClass]="getOrangeClass(i)">
                        <a href="{{this.getBasePath()}}/work_packages/{{item.wpid}}"
                           [textContent]="shorten(item.wptitle)" [title]="item.wptitle"></a>
                    </td>
                </tr>
                <tr *ngIf="loadingProblems">
                    <td [ngClass]="getOrangeClass(0)" colspan="3">Загрузка...</td>
                </tr>
                <tr *ngIf="!loadingProblems && problemCount === 0">
                    <td [ngClass]="getOrangeClass(0)" colspan="3">Нет данных</td>
                </tr>
                </tbody>
            </table>
            <pagination-footer  *ngIf="problemPages > 1"
                                [hidden]="problemVisibility"
                                [pages]="problemPages"
                                (changePageEvent)="loadProblemsByPage($event)">

            </pagination-footer>
        </div>
        <div style="width: 25%">
            <table border="0" class="coloredTable">
                <thead (click)="changeBudgetVisibility()">
                <tr>
                    <th class="colored-col-purple" colspan="3">Бюджет: страница {{budgetPage}}</th>
                </tr>
                </thead>
                <tbody [hidden]="budgetVisibility">
                <tr *ngFor="let item of budgetData; let i = index">
                    <td *ngIf="item"
                        [ngClass]="getPurpleClass(i)"
                        [textContent]="item.number"></td>
                    <td *ngIf="item"
                        [ngClass]="getPurpleClass(i)">
                        <a href="{{this.getBasePath()}}/projects/{{item.id}}"
                           [textContent]="shorten(item.name)" [title]="item.name"></a>
                    </td>
                    <td *ngIf="item"
                        [ngClass]="getPurpleClass(i)" [textContent]="item.value.toFixed(2)">
                    </td>
                </tr>
                <tr *ngIf="loadingBudgets">
                    <td [ngClass]="getPurpleClass(0)" colspan="3">Загрузка...</td>
                </tr>
                <tr *ngIf="!loadingBudgets && budgetCount === 0">
                    <td [ngClass]="getPurpleClass(0)" colspan="3">Нет данных</td>
                </tr>
                </tbody>
            </table>
            <pagination-footer  *ngIf="budgetPages > 1"
                                [hidden]="budgetVisibility"
                                [pages]="budgetPages"
                                (changePageEvent)="loadBudgetsByPage($event)">

            </pagination-footer>
        </div>
    </div>
</div>

