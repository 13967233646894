
<div class="work-package--details--long-field work-packages--activity--add-comment hide-when-print">
  <style type="text/css">
    table { width: 100%; }
    td.colA { width: 20%; }
    td.colB { width: 14%; }
  </style>

  <div class="wp-relations-create">
    <div class="wp-relations-create-button hide-when-print"
         *ngIf="!showProblemCreateForm">
      <div class="grid-block">
        <div class="grid-content collapse wp-inline-create-button">
          <a class="wp-inline-create--add-link relation-create"
             #focusAfterSave
             (accessibleClick)="toggleProblemCreateForm()"
             href
             id="relation--add-relation">
            <op-icon icon-classes="icon icon-add"></op-icon>
            <span [textContent]="text.addNewProblem"></span>
          </a>
        </div>
      </div>
    </div>
    <div>
      <div class="v-align wp-relations-create--form" *ngIf="showProblemCreateForm">
        <section>
          <h4>Введите данные</h4>
          <div>
            <input type="hidden" [(ngModel)]="wpProblem.project_id" [value]="workPackage.project.id" />
            <label>Тип</label>
            <div style="float:left; width: 100%;">
              <label style="width: 260px; float:left;"><input type="radio" name="problem_type"
                                                              [(ngModel)]="wpProblem.problem_type" value="problem"/>Проблема</label>
              <label><input type="radio" name="problem_type" [(ngModel)]="wpProblem.problem_type" value="risk" />Риск</label>
            </div>

            <div *ngIf="wpProblem.problem_type == 'risk'">
              <label for="input_risk">Риск</label>
              <select name="risk" id="input_risk" [(ngModel)]="wpProblem.risk_id">
                <option *ngFor="let risk of risks | keyvalue" value="{{ risk.key }}">{{ risk.value }}</option>
              </select>
            </div>

            <div style="float:left; width: 100%;">
              <label for="input_org" style="margin-top: 10px;">Организация источник</label>
              <select name="org" id="input_org" [(ngModel)]="wpProblem.organization_source_id">
                <option *ngFor="let org of orgs | keyvalue" value="{{ org.key }}">{{ org.value }}</option>
              </select>
              <label for="input_user" style="margin-top: 10px;">Сотрудник источник</label>
              <select id="input_user" name="user" [(ngModel)]="wpProblem.user_source_id">
                <option *ngFor="let user of users | keyvalue" value="{{ user.key }}">{{ user.value }}</option>
              </select>
            </div>

            <div style="float:left; width: 100%;">
              <label for="input_desc" style="margin-top: 10px;">Описание</label>
              <textarea id="input_desc" name="desc" [(ngModel)]="wpProblem.description"></textarea>
            </div>

            <div style="margin-top: 10px; float: right">
              <accessible-by-keyboard
                      (execute)="createProblem()"
                      [isDisabled]="isDisabled"
                      linkClass="wp-create-relation--save"
                      aria-hidden="false">
                <op-icon icon-classes="icon-checkmark -padded" [icon-title]="text.save"></op-icon>
              </accessible-by-keyboard>
              <accessible-by-keyboard
                      (execute)="toggleProblemCreateForm()"
                      linkClass="wp-create-relation--cancel"
                      aria-hidden="false">
                <op-icon icon-classes="icon-remove -padded" [icon-title]="text.abort"></op-icon>
              </accessible-by-keyboard>
            </div>

            <!--<input type="hidden" [(ngModel)]="wpProblem.status" value="created"/>-->
            <!--<div style="float:left; width: 100%;">-->
              <!--<label for="input_status">Статус решения</label>-->
              <!--<select id="input_status" name="status" [(ngModel)]="wpProblem.status">-->
                <!--<option value="0" selected="selected">Создано</option>-->
                <!--<option value="1">Решено</option>-->
              <!--</select>-->
            <!--</div>-->

            <!--<div style="float:left; width: 100%;">-->
              <!--<label for="input_date">Дата решения</label>-->
              <!--<input id="input_date" type="date" name="solution_date" [(ngModel)]="wpProblem.solution_date" />-->
            <!--</div>-->
          </div>
        </section>
      </div>
    </div>
  </div>

  <section style="margin-top: 20px;">
    <div class="wp-relations-hierarchy-section">
      <div class="attributes-group--header">
        <div class="attributes-group--header-container">
          <h3 class="attributes-group--header-text">
            {{'Проблемы и риски мероприятия'}}
          </h3>
        </div>
      </div>
    </div>
    <div class="container">
      <div *ngFor="let problem of wpProblems" class="problem-row">
        <ng-template [ngTemplateOutlet]="loadTemplate(problem)"
                     [ngTemplateOutletContext]="{ $implicit: problem }">
        </ng-template>

      </div>
    </div>
  </section>

</div>

<ng-template #readOnlyTemplate2 let-problem>
  <div class="problem-cells">
    <div class="problem-cell">
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label for="name">Тип, Название</label>
        <span id="name">
                {{ I18n.t('js.problem_buttons.'+problem.problem_type) }}  {{ problem.name }}
              </span>
      </div>
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label for="desc">Описание</label>
        <span id="desc">
                {{ problem.description }}
              </span>
      </div>
    </div>
    <div class="problem-cell">
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label for="org">Организация инициатор</label>
        <span id="org">
                {{ orgs.get(problem.organization_source_id) }}
              </span>
      </div>
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label for="source-user">Сотрудник инициатор</label>
        <span id="source-user">
                {{ users.get(problem.user_source_id) }}
              </span>
      </div>
    </div>
    <div class="problem-cell">
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label>Статус решения</label>
        <span *ngIf="problem.status == 'solved'; else created" style="color: green;">
          {{ I18n.t('js.problem_buttons.'+problem.status) }}
        </span>
        <ng-template #created>
          <span style="color: lightcoral;">
            {{ I18n.t('js.problem_buttons.'+problem.status) }}
          </span>
        </ng-template>
      </div>
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label for="sol-date">Дата решения</label>
        <span id="sol-date">
          {{ problem.solution_date }}
        </span>
      </div>
    </div>
    <div class="problem-cell">
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label for="create-user">Кто создал</label>
        <span id="create-user">
          {{ users.get(problem.user_creator_id) }}
        </span>
      </div>
      <div style="display: block; float: left; position: relative; width: 100%;">
      </div>
    </div>
  </div>
  <div class="buttons">
    <accessible-by-keyboard *ngIf="problemCanEdit"
                            (execute)="editProblem(problem)"
                            [linkTitle]="text.edit"
                            class="edit-activity">
      <op-icon icon-classes="action-icon icon-edit" [icon-title]="text.edit"></op-icon>
    </accessible-by-keyboard>
    <accessible-by-keyboard (execute)="deleteProblem(problem)"
                            aria-hidden="false"
                            [linkAriaLabel]="text.removeButton"
                            [linkTitle]="text.removeButton"
                            linkClass="relation-row--remove-btn">
      <op-icon icon-classes="icon-remove -padded wp-relations--icon" [icon-title]="text.removeButton"></op-icon>
    </accessible-by-keyboard>
  </div>
</ng-template>

<ng-template #editTemplate2>
  <div class="problem-cells">
    <div class="problem-cell">
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label >Тип, Название</label>
        <span >
          {{ I18n.t('js.problem_buttons.'+editedProblem.problem_type) }}  {{ editedProblem.name }}
        </span>
      </div>
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label>Описание</label>
        <textarea [(ngModel)]="editedProblem.description"></textarea>
      </div>
    </div>
    <div class="problem-cell">
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label>Организация инициатор</label>
        <select name="org" [(ngModel)]="editedProblem.organization_source_id">
          <option *ngFor="let org of orgs | keyvalue" value="{{ org.key }}">{{ org.value }}</option>
        </select>
      </div>
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label>Сотрудник инициатор</label>
        <select name="user" [(ngModel)]="editedProblem.user_source_id">
          <option *ngFor="let user of users | keyvalue" value="{{ user.key }}">{{ user.value }}</option>
        </select>
      </div>
    </div>
    <div class="problem-cell">
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label>Статус решения</label>
        <select name="status" [(ngModel)]="editedProblem.status">
          <option value="created">Создана</option>
          <option value="solved">Решена</option>
        </select>
      </div>
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label>Дата решения</label>
        <input type="date" [(ngModel)]="editedProblem.solution_date" />
      </div>
    </div>
    <div class="problem-cell">
      <div style="display: block; float: left; position: relative; width: 100%;">
        <label>Кто создал</label>
        <span>
          {{ users.get(editedProblem.user_creator_id) }}
        </span>
      </div>
      <div style="display: block; float: left; position: relative; width: 100%;">
      </div>
    </div>
  </div>
  <div class="buttons">
        <accessible-by-keyboard
                (execute)="saveWpProblem(editedProblem)"
                linkClass="wp-create-relation--save"
                aria-hidden="false">
            <op-icon icon-classes="icon-checkmark -padded" [icon-title]="text.save"></op-icon>
        </accessible-by-keyboard>
        <accessible-by-keyboard
                (execute)="cancelEdit()"
                linkClass="wp-create-relation--cancel"
                aria-hidden="false">
            <op-icon icon-classes="icon-remove -padded" [icon-title]="text.abort"></op-icon>
        </accessible-by-keyboard>
  </div>
</ng-template>
