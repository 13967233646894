<h2>Светофор</h2>
<br>
<div id="form">
    <label>Светофор №1</label>
    <span class="form--select-container -middle">
        <select id="type-select" [(ngModel)]="selectedValue" container_class="-middle" class="form--select">
            <option *ngFor="let type of types" [value]="type.id">{{type.name}}</option>
        </select>
    </span>
    <br>
    <div class="form--check-box-container">
        <div *ngFor="let status of statuses" style="margin-top: 12px">
            <input type="checkbox"
                   [ngModel]="selectedStatuses1"
                   (ngModelChange)="selectStatus(status.id, $event, 1)"
                   class="form--check-box"
                   style="vertical-align: middle"/>
            <span [textContent]="status.name" style="vertical-align: middle; margin-left: 5px"></span>
            <br>
        </div>
    </div>
    <br>
    <button class="-highlight -with-icon icon-checkmark button" (click)="submit()">Сформировать</button>
    <hr style="width: 350px" class="solid">
    <br>
    <label>Светофор №2</label>
    <span class="form--select-container -middle">
        <select id="type-select2" [(ngModel)]="selectedValue2" container_class="-middle" class="form--select">
            <option *ngFor="let type of types2" [value]="type.id">{{type.name}}</option>
        </select>
    </span>
    <br>
    <div class="form--check-box-container">
        <div *ngFor="let status of statuses" style="margin-top: 12px">
            <input type="checkbox"
                   [ngModel]="selectedStatuses2"
                   (ngModelChange)="selectStatus(status.id, $event, 2)"
                   class="form--check-box"
                   style="vertical-align: middle"/>
            <span [textContent]="status.name" style="vertical-align: middle; margin-left: 5px"></span>
            <br>
        </div>
    </div>
    <br>
<!--    ! TODO: раскомментировать после добавления jospd       -->
<!--    <button class="-highlight -with-icon icon-checkmark button" (click)="submitsecond()">Сформировать</button>-->
</div>
