export class Contract {
  id?:number;
  projectId?:number|null;
  contractSubject?:string;
  contractDate?:any;
  price?:number;
  executor?:string;
  createdAt?:string;
  updatedAt?:string;
  contractNum?:string;
  isApprove?:boolean;
  eisHref?:string;
  name?:string;
  sposob?:string;
  gosZakaz?:string;
  dateBegin?:any;
  dateEnd?:any;
  etaps?:string;
  auctionDate?:any;
  scheduleDate?:any;
  nmck?:number;
  scheduleDatePlan?:any;
  notificationDatePlan?:any;
  notificationDate?:any;
  auctionDatePlan?:any;
  contractDatePlan?:any;
  dateEndPlan?:any;
  note?:string;
  conclusionOfEstimatedCostDetails?:string;
  conclusionOfEstimatedCostNumber?:string;
  conclusionOfEstimatedCostDate?:any;
  conclusionOfProjectDocumentationDetails?:string;
  conclusionOfProjectDocumentationNumber?:string;
  conclusionOfProjectDocumentationDate?:any;
  conclusionOfEcologicalExpertiseDetails?:string;
  conclusionOfEcologicalExpertiseNumber?:string;
  conclusionOfEcologicalExpertiseDate?:any;
}
