<h2>Исполнение показателей</h2>

<div ng-app="OpenProjectLegacy" class="ng-scope">
    <div id="invisible-grid" class="widget-boxes project-overview">
        <div class="grid-block" style="overflow-x: scroll;">
            <div style="flex:auto;display: flex;align-items: center;justify-content: center;"
                 *ngFor="let chartData of chartsData; let i = index">
                <div style="max-width:350px">
                    <p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: center;font-weight: bold;margin: -2px 0; height: 50px"
                       [title]="chartData.name">{{chartData.name}}</p>
                    <homescreen-diagram [data]="chartData.data" [label]='chartData.label'
                                        [chartLabels]='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]'>
                    </homescreen-diagram>
                </div>
            </div>
        </div>
    </div>
    <homescreen-blue-table [template]="'indicator'"></homescreen-blue-table>
</div>

