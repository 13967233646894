<div>
    <button mat-icon-button style="float: right" [mat-dialog-close]="true">
        <i class="icon-remove"></i>
    </button>
</div>
<p style="text-align:center"><span style="font-family:Times New Roman,Times,serif"><strong><span style="font-size:16px">Источники финансирования в Электронном Бюджете </span></strong></span></p>

<p><span style="font-family:Times New Roman,Times,serif"><span>Взято из документации &quot;Описание форматов данных загрузки исполнений&quot;, стр. 14.</span></span></p>

<blockquote>
    <p><span style="font-family:Times New Roman,Times,serif"><span>100 Межбюджетные трансферты из федерального бюджета (справочно).</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>200 Межбюджетные трансферты из ГВБФ (справочно).</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>201 Сумма соглашений (справочно).</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>210 консолидированный бюджет субъекта, из них.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>220 бюджет субъекта, из них.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>22010 межбюджетные трансферты, из них.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>221 Федеральному бюджету (межбюджетные трансферты в федеральный бюджет).</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>222 бюджетам государственных внебюджетных фондов РФ, из них.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>22210 бюджету Пенсионного фонда РФ.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>22220 бюджету Фонда социального страхования РФ.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>22230 бюджету Федерального фонда обязательного медицинского страхования РФ.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>224 бюджетам территориальных государственных внебюджетных фондов (ТФОМС).</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>223 местным бюджетам.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>225 иным бюджетам субъектов РФ.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>240 бюджеты территориальных государственных внебюджетных фондов (ТФОМС), из них.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>24010 межбюджетные трансферты, из них.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>24011 бюджетам субъектов РФ.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>24012 бюджетам государственных внебюджетных фондов РФ (ТФОМС).</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>230 свод бюджетов Муниципальных образований, из них.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>231 межбюджетные трансферты в БС</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>232 межбюджетные трансферты в МО других субъектов РФ.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>250 Внебюджетные источники, из них.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>251 определенные на федеральном уровне.</span></span></p>

    <p><span style="font-family:Times New Roman,Times,serif"><span>252 привлеченные субъектом РФ</span></span></p>
</blockquote>

<p>&nbsp;</p>

<p><span style="font-family:Times New Roman,Times,serif"><span style="font-size:small">&nbsp;</span></span></p>

