// Legacy code ported from app/assets/javascripts/application.js.erb
// Do not add stuff here, but ideally remove into components whenever changes are necessary
export function setupServerResponse() {
  initMainMenuExpandStatus();
  focusFirstErroneousField();
  activateFlashNotice();
  activateFlashError();
  autoHideFlashMessage();
  flashCloseHandler();
  openAddTargetForm();
  openAddTargetRiskForm();
  calculateBudget();
  validateFilterDate();

  jQuery(document).ajaxComplete(activateFlashNotice);
  jQuery(document).ajaxComplete(activateFlashError);

  /*
  * 1 - registers a callback which copies the csrf token into the
  * X-CSRF-Token header with each ajax request.  Necessary to
  * work with rails applications which have fixed
  * CVE-2011-0447
  * 2 - shows and hides ajax indicator
  */
  jQuery(document).ajaxSend((event, request) => {
    if (jQuery(event.target.activeElement!).closest('[ajax-indicated]').length
      && jQuery('ajax-indicator')) {
      jQuery('#ajax-indicator').show();
    }

    const csrf_meta_tag = jQuery('meta[name=csrf-token]');

    if (csrf_meta_tag) {
      const header = 'X-CSRF-Token';
      const token = csrf_meta_tag.attr('content');

      request.setRequestHeader(header, token!);
    }

    request.setRequestHeader('X-Authentication-Scheme', 'Session');
  });

  // ajaxStop gets called when ALL Requests finish, so we won't need a counter as in PT
  jQuery(document).ajaxStop(() => {
    if (jQuery('#ajax-indicator')) {
      jQuery('#ajax-indicator').hide();
    }
    addClickEventToAllErrorMessages();
  });

  // show/hide the files table
  jQuery('.attachments h4').click(function () {
    jQuery(this).toggleClass('closed').next().slideToggle(100);
  });

  let resizeTo:any = null;
  jQuery(window).on('resize', () => {
    // wait 200 milliseconds for no further resize event
    // then readjust breadcrumb

    if (resizeTo) {
      clearTimeout(resizeTo);
    }
    resizeTo = setTimeout(() => {
      jQuery(window).trigger('resizeEnd');
    }, 200);
  });

  // Do not close the login window when using it
  jQuery('#nav-login-content').click((event) => {
    event.stopPropagation();
  });

  // Set focus on first error message
  const error_focus = jQuery('a.afocus').first();
  const input_focus = jQuery('.autofocus').first();
  if (error_focus !== undefined) {
    error_focus.focus();
  } else if (input_focus !== undefined) {
    input_focus.focus();
    if (input_focus[0].tagName === 'INPUT') {
      input_focus.select();
    }
  }
  // Focus on field with error
  addClickEventToAllErrorMessages();

  // Click handler for formatting help
  jQuery(document.body).on('click', '.formatting-help-link-button', () => {
    window.open(`${window.appBasePath}/help/wiki_syntax`,
      '',
      'resizable=yes, location=no, width=600, height=640, menubar=no, status=no, scrollbars=yes');
    return false;
  });
}

function flashCloseHandler() {
  jQuery('body').on('click keydown touchend', '.close-handler,.op-toast--close', function (e) {
    if (e.type === 'click' || e.which === 13) {
      jQuery(this).parent('.errorExplanation, .op-toast')
        .not('.persistent-toggle--notification')
        .remove();
    }
  });
}

function autoHideFlashMessage() {
  setTimeout(() => {
    jQuery('.op-toast.autohide-toaster').remove();
  }, 5000);
}

function addClickEventToAllErrorMessages() {
  jQuery('a.afocus').each(function () {
    const target = jQuery(this);
    target.click((evt) => {
      let field = jQuery(`#${target.attr('href')!.substr(1)}`);
      if (field === null) {
        // Cut off '_id' (necessary for select boxes)
        field = jQuery(`#${target.attr('href')!.substr(1).concat('_id')}`);
      }
      target.unbind(evt);
      return false;
    });
  });
}

function initMainMenuExpandStatus() {
  const wrapper = jQuery('#wrapper');
  const upToggle = jQuery('ul.menu_root.closed li.open a.arrow-left-to-project');

  if (upToggle.length === 1 && wrapper.hasClass('hidden-navigation')) {
    upToggle.trigger('click');
  }
}

function activateFlash(selector:any) {
  const flashMessages = jQuery(selector);

  flashMessages.each((ix, e) => {
    const flashMessage = jQuery(e);
    flashMessage.show();
  });
}

function activateFlashNotice() {
  activateFlash('.op-toast');
}

function activateFlashError() {
  activateFlash('.errorExplanation[role="alert"]');
}

function focusFirstErroneousField() {
  const firstErrorSpan = jQuery('span.errorSpan').first();
  const erroneousInput = firstErrorSpan.find('*').filter(':input');

  erroneousInput.trigger('focus');
}

// TODO: наверное стоить переписать код
function openAddTargetForm() {
  jQuery( document ).ready(function() {
    let row_id: number | string = 0;
    let add_form = 0;
    jQuery('#target-val-add').click(function(){
      if(row_id == 0 ) {
        jQuery(this).hide();
        jQuery('#target-val-form').show("fast");
        add_form = 1;
      }
    });

    jQuery('#target-val-cancel').click(function(){
      jQuery('#target-val-add').show("fast");
      jQuery('#target-val-form').hide("fast");
      add_form = 0;
    });

    jQuery('.target-val-edit').click(function(){
      if(add_form == 0) {
        if (row_id != 0) {
          jQuery("#row_" + row_id).show("fast");
        }
        const dataId: string | undefined = jQuery(this).attr('data-id');
        if (dataId !== undefined) {
          row_id = dataId;
          jQuery("#row_" + row_id).hide();
          const year = jQuery("#row_" + row_id).find("td.year").text().replace(/\s/g, '');
          const quarter = jQuery("#row_" + row_id).find("td.quarter").text().replace(/\s/g, '');
          const value = jQuery("#row_" + row_id).find("td.value").text().replace(/\s/g, '');
          const attrEditForm = jQuery('#target-val-edit-form').attr('action');

          if (attrEditForm !== undefined) {
            let action = attrEditForm;
            action = action.substring(0, action.length - 1) + row_id;
            jQuery('#target-val-edit-form').attr('action', action);
            jQuery('#val-year').val(year);
            if (quarter != "") {
              jQuery('select[name="target_execution_value[quarter]"] option[value=' + quarter.replace(/\s/g, '') + ']').prop('selected', true);
            }
            jQuery('#val-value').val(value);
            jQuery('#target-val-edit-block').show("fast");
          }
        }
      }
    });

    jQuery('#target-val-edit-cancel').click(function(){
      jQuery("#row_"+row_id).show("fast");
      jQuery('#target-val-edit-block').hide("fast");
      row_id = 0;
    });

    jQuery('#upload-plan-type').change(function(){
      if(jQuery('#upload-plan-type option:selected').val() == "UploadPlanType6"){
        jQuery('#upload-plan-type6-notify').show();
      }
      else{
        jQuery('#upload-plan-type6-notify').hide();
      }
    });
  });
}

function openAddTargetRiskForm() {
  jQuery( document ).ready(function() {
    let row_id: number | string = 0;
    let add_form = 0;
    jQuery('#target-risk-add').click(function(){
      if(row_id == 0 ) {
        jQuery(this).hide();
        jQuery('#target-risk-form').show("fast");
        add_form = 1;
      }
    });

    jQuery('#target-risk-cancel').click(function(){
      jQuery('#target-risk-add').show("fast");
      jQuery('#target-risk-form').hide("fast");
      add_form = 0;
    });


    jQuery('.target-risk-edit').click(function(){
      if(add_form == 0) {
        if (row_id != 0) {
          jQuery("#row_" + row_id).show("fast");
        }
        const dataId:string|undefined = jQuery(this).attr('data-id');

        if (dataId !== undefined) {
          row_id = dataId;
          jQuery("#row_" + row_id).hide();
          const attrRowId =  jQuery("#row_" + row_id).attr("class");

          if (attrRowId !== undefined) {
            const class_name = attrRowId
            const risk_id = class_name.replace('risk_id_', '')
            const solution_date = jQuery("#row_" + row_id).find("td.solution_date").text().replace(/\s/g, '');
            const is_solved = jQuery("#row_" + row_id).find("td.is_solved").find("i").attr('aria-hidden');
            const actattrEditForm = jQuery('#target-risk-edit-form').attr('action');

            if (actattrEditForm !== undefined) {
              let action = actattrEditForm
              action = action.substring(0, action.length - 1) + row_id;
              jQuery('#target-risk-edit-form').attr('action', action);
              jQuery('#val-solution-date').val(solution_date);

              if (risk_id != "") {
                jQuery('select[name="target_risk[risk]"] option[value=' + risk_id.replace(/\s/g, '') + ']').prop('selected', true);
              }

              if (is_solved) {
                jQuery('#val-is-solved').val(is_solved);
              }
              jQuery('#target-risk-edit-block').show("fast");
            }
          }
        }
      }
    });

    jQuery('#target-risk-edit-cancel').click(function(){
      jQuery("#row_"+row_id).show("fast");
      jQuery('#target-risk-edit-block').hide("fast");
      row_id = 0;
    });
  });
}

function calculateBudget() {
  jQuery(document).ready(function($){
    // TODO: временно закомментирован, так как нужно описать treetable
    // jQuery('#material_budget_items').treetable({
    //   expandable: true,
    //   initialState: "expanded",
    //   column: 1,
    // });
    let total_budget = 0;
    let total_spent = 0;
    let total_available = 0;

    jQuery('.cost-object-treetable-main-parent').each(function (index, element) {
      const budget = element.getAttribute('budget');
      const spent = element.getAttribute('spent');
      const available = element.getAttribute('available');

      if (budget !== null && spent !== null && available !== null) {
        total_budget += parseFloat(budget);
        total_spent += parseFloat(spent);
        total_available += parseFloat(available);
      }
    });

    jQuery('#cost-object-budget-total').text(total_budget.toLocaleString('en-EN', {minimumFractionDigits: 0, maximumFractionDigits: 0}) + " руб");
    jQuery('#cost-object-spent-total').text(total_spent.toLocaleString('en-EN', {minimumFractionDigits: 0, maximumFractionDigits: 0}) + " руб");
    jQuery('#cost-object-available-total').text(total_available.toLocaleString('en-EN', {minimumFractionDigits: 0, maximumFractionDigits: 0}) + " руб");
  });
}

function limitDateLength(date: string, maxLength: number): string {
  if (date.length > maxLength) {
    date = date.slice(0, maxLength);
  }

  return date;
}

function limitMonths(date: string): string {
  if (date.length >= 5 && date.length > 6) {
    let month: number = parseInt(date.slice(4, 6), 10);

    if (month > 12) {
      month = 12;
    }
    date = date.slice(0, 4) + ('0' + month).slice(-2) + date.slice(6);
  }

  return date;
}

function limitDays(date: string): string {
  if (date.length > 7) {
    let days: number = parseInt(date.slice(6, 8), 10);

    if (days > 31) {
      days = 31;
    }
    date = date.slice(0, 6) + ('0' + days).slice(-2) + date.slice(8);
  }

  return date;
}

function addDashInDate(date: string): string {
  let result: string = '';

  for (let i: number = 0; i < date.length; i++) {
    if ((i === 4 || i === 6) && date[i] !== '-') {
      result += '-';
    }
    result += date[i];
  }

  return result;
}

function editJQueryInputDate(date: string | number | string[]): string {
  let inputDate: string = date.toString().replace(/\D/g, '');
  inputDate = limitDateLength(inputDate, 8);
  inputDate = limitMonths(inputDate);
  inputDate = limitDays(inputDate);
  inputDate = addDashInDate(inputDate);

  return inputDate;
}

function validateFilterDate(): void {
  jQuery(document).ready(function () {
    jQuery('#label_by_date_from, #label_by_date_to').on('input', function () {
      let inputDate: string | number | string[] | undefined =  jQuery(this).val();

      inputDate = (inputDate === undefined) ? '' : editJQueryInputDate(inputDate);

      jQuery(this).val(inputDate);
    });
  });
}
