<div style="padding:10px; text-align: center;">{{zagolovok}}</div>
<div class="chart" [ngStyle]="hideMe()">
    <button class="changeChart" type="button" (click)="refresh()" style="display: none"></button>
    <canvas id="chart" baseChart height="300px"
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [type]="barChartType">
    </canvas>
</div>
