<div class="container-h" style="margin: auto;padding: 0 40px;">
    <div class="container-h" style="flex-grow: 1;justify-content: flex-start;">
        <img src="https://egov-buryatia.ru/upload/iblock/017/0170a5bc251fc6b518ace58306e5dcc3.png">
        <div style="display: flex; flex-direction: column;">
            <a href="/public_pages" style="text-decoration: none; color: white">
                <div class="logo-text" style="font-size: 18px"><%= t(:label_portfolios) %></div>
            </a>
        </div>
    </div>
    <a class="on-hover" href="http://egov-buryatia.ru/" style="text-decoration: none;">Официальный
        портал<br></a>
</div>
