<div ng-app="OpenProjectLegacy" class="ng-scope">
    <div id="invisible-grid" class="widget-boxes project-overview">
        <div style="overflow-x: scroll;" class="grid-block">
            <div style="flex:auto;display: flex;align-items: center;justify-content: center;">
                <div style="max-width:350px">
                    <p style="text-align: center;font-weight: bold;margin: -2px;"> Показатели</p>
                    <homescreen-diagram [data]="this.targetChartData" [label]='this.targetChartLabel'
                                        [chartLabels]='["Нет откл.","Небольшие откл.","Значит. откл", "Нет данных"]'
                                        [chartColors]='["#00b050","#1f497d","#c00000","#ffc000"]'>
                    </homescreen-diagram>
                </div>
            </div>
            <div style="flex:auto;display: flex;align-items: center;justify-content: center;">
                <div style="max-width:350px">
                    <p style="text-align: center;font-weight: bold;margin: -2px;">КТ</p>
                    <homescreen-diagram [data]="this.KTChartData" [label]='this.KTChartLabel'
                                        [chartLabels]='["Исп.","Не исп.","В работе","Риски"]'>
                    </homescreen-diagram>
                </div>
            </div>
            <div style="flex:auto;display: flex;align-items: center;justify-content: center;">
                <div style="max-width:350px">
                    <p style="text-align: center;font-weight: bold;margin: -2px;">Бюджет</p>
                    <homescreen-diagram [data]="this.budgetChartData" [label]='this.budgetChartLabel'
                                        [chartLabels]='["Исп.","Не исп.","Остаток"]'
                                        [chartColors]='["#00b050", "#c00000", "#1f497d"]'>
                    </homescreen-diagram>
                </div>
            </div>
            <div style="flex:auto;display: flex;align-items: center;justify-content: center;">
                <div style="max-width:350px">
                    <p style="text-align: center;font-weight: bold;margin: -2px;">Риски</p>
                    <!--
                    <homescreen-diagram chart-name='riski' chart-labels='["Отсутств.", "Незначит.","Критич."]' chart-colors='["#00b050", "#ffc000", "#c00000"]'>
                    -->
                    <homescreen-diagram [data]="this.risksAndProblemsChartData"
                                        [label]='this.risksAndProblemsChartLabel'
                                        [chartLabels]='["Незначит.","Критич."]'
                                        [chartColors]='["#ffc000", "#c00000"]'>
                    </homescreen-diagram>
                </div>
            </div>
            <div style="text-align: center; width: 100px; background-color: #c0504d; margin-right: 10px;">
                <a style="font-weight: bold; color: white; position:relative; top:40%" class="problems-a"
                   href="{{this.getBasePath()}}/vkladka1/problems">Проблем<br>{{problemCount}}</a>
            </div>
        </div>
    </div>
<!--    <div class="grid-block" style="border: 2px solid #1C6EA4;border-radius: 5px;display: inline-flex;margin: 10px 0px">-->

<!--        <button style="border: none;background: #1C6EA4;" class="icon-search"></button>-->
<!--    </div>-->
    <div class="ng-autocomplete" style="width: 100%;border: 2px solid #1C6EA4;display: inline-flex;margin: 10px 0;">
        <ng-autocomplete #autocomplete
                         [data]="data_autocomplete"
                         [searchKeyword]="keyword"
                         [isLoading]="check_load()"
                         [disabled]="check_load()"
                         placeHolder="Все проекты..."
                         (selected)="selectEvent($event)"
                         (inputCleared)="selectEvent({id: '0', name: 'Все проекты'})"
                         [itemTemplate]="itemTemplate"
                         [ngStyle]="{'width': '100%'}"
                         [notFoundTemplate]="notFoundTemplate"
                         debounceTime="1000"
                         notFoundText="Не найдено">
        </ng-autocomplete>
        <button class="icon-search" style="color: white;width: 8%;background: #1C6EA4;border: 1px #1C6EA4"></button>
        <ng-template #itemTemplate let-item >
            <a [innerHTML]="item.name" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis; padding: 5px 5px;"></a>
        </ng-template>
        <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
        </ng-template>
    </div>
    <homescreen-blue-table [template]="'desktop'"></homescreen-blue-table>
    <br/>
    <div style="display: inline-flex; width: 100%;">
        <div style="width: 25%">
            <table border="0" class="coloredTable">
                <thead (click)="changeUpcomingTasksVisibility()">
                <tr>
                    <th class="colored-col-green" colspan="3">Предстоящие мероприятия: страница {{upcomingTasksPage}}</th>
                </tr>
                </thead>
                <tbody [hidden]="upcomingTasksVisibility">
                <tr *ngFor="let item of upcomingTasksData; let i = index">
                    <td [ngClass]="getGreenClass(i)"
                        [textContent]="item.number"></td>
                    <td [ngClass]="getGreenClass(i)">
                        <a href="{{this.getBasePath()}}/projects/{{item.projectId}}/work_packages/{{item.id}}/activity"
                           [textContent]="shorten(item.subject)" [title]="item.subject"></a>
                    </td>
                    <td [ngClass]="getGreenClass(i)">
                        <a href="{{this.getBasePath()}}/projects/{{item.projectId}}"
                           [textContent]="shorten(item.project)" [title]="item.project"></a>
                    </td>
                </tr>
                <tr *ngIf="loadingUpcomingTasks">
                    <td [ngClass]="getGreenClass(0)" colspan="3">Загрузка...</td>
                </tr>
                <tr *ngIf="!loadingUpcomingTasks && upcomingTasksCount === 0">
                    <td [ngClass]="getGreenClass(0)" colspan="3">Нет данных</td>
                </tr>
                </tbody>
            </table>
            <pagination-footer  [hidden]="upcomingTasksVisibility"
                                [pages]="upcomingTasksPages"
                                (changePageEvent)="loadUpcomingTasksByPage($event)">

            </pagination-footer>
        </div>
        <div style="width: 25%">
            <table border="0" class="coloredTable">
                <thead (click)="changeDueMilestoneVisibility()">
                <tr>
                    <th class="colored-col-red" colspan="3">Просроченные КТ: страница {{dueMilestonePage}}</th>
                </tr>
                </thead>
                <tbody [hidden]="dueMilestoneVisibility">
                <tr *ngFor="let item of dueMilestoneData; let i = index">
                    <td [ngClass]="getRedClass(i)"
                        [textContent]="item.number"></td>
                    <td [ngClass]="getRedClass(i)">
                        <a href="{{this.getBasePath()}}/projects/{{item.projectId}}/work_packages/{{item.id}}/activity?"
                           [textContent]="shorten(item.subject)" [title]="item.subject"></a>
                    </td>
                    <td [ngClass]="getRedClass(i)">
                        <a href="{{this.getBasePath()}}/projects/{{item.projectId}}"
                           [textContent]="shorten(item.project)" [title]="item.project"></a>
                    </td>
                </tr>
                <tr *ngIf="loadingDueMilestones">
                    <td [ngClass]="getRedClass(0)" colspan="3">Загрузка...</td>
                </tr>
                <tr *ngIf="!loadingDueMilestones && dueMilestoneCount === 0">
                    <td [ngClass]="getRedClass(0)" colspan="3">Нет данных</td>
                </tr>
                </tbody>
            </table>
            <pagination-footer  [hidden]="dueMilestoneVisibility"
                                [pages]="dueMilestonePages"
                                (changePageEvent)="loadDueMilestonesByPage($event)">

            </pagination-footer>
        </div>
        <div style="width: 25%">
            <table border="0" class="coloredTable">
                <thead (click)="changeProblemVisibility()">
                <tr>
                    <th class="colored-col-orange" colspan="3">Проблемы: страница {{problemPage}}</th>
                </tr>
                </thead>
                <tbody [hidden]="problemVisibility">
                <tr *ngFor="let item of problemData; let i = index">
                    <td [ngClass]="getOrangeClass(i)"
                        [textContent]="item.number"></td>
                    <td [ngClass]="getOrangeClass(i)">
                        <a href="{{this.getBasePath()}}/work_packages/{{item.wpid}}/problems"
                           [textContent]="shorten(item.risk)" [title]="item.risk"></a>
                    </td>
                    <td [ngClass]="getOrangeClass(i)">
                        <a href="{{this.getBasePath()}}/work_packages/{{item.wpid}}"
                           [textContent]="shorten(item.wptitle)" [title]="item.wptitle"></a>
                    </td>
                </tr>
                <tr *ngIf="loadingProblems">
                    <td [ngClass]="getOrangeClass(0)" colspan="3">Загрузка...</td>
                </tr>
                <tr *ngIf="!loadingProblems && problemCount === 0">
                    <td [ngClass]="getOrangeClass(0)" colspan="3">Нет данных</td>
                </tr>
                </tbody>
            </table>
            <pagination-footer  [hidden]="problemVisibility"
                                [pages]="problemPages"
                                (changePageEvent)="loadProblemsByPage($event)">

            </pagination-footer>
        </div>
        <div style="width: 25%">
            <table border="0" class="coloredTable">
                <thead (click)="changeBudgetVisibility()">
                <tr>
                    <th class="colored-col-purple" colspan="3">Бюджет: страница {{budgetPage}}</th>
                </tr>
                </thead>
                <tbody [hidden]="budgetVisibility">
                <tr *ngFor="let item of budgetData; let i = index">
                    <td *ngIf="item"
                        [ngClass]="getPurpleClass(i)"
                        [textContent]="item.number"></td>
                    <td *ngIf="item"
                        [ngClass]="getPurpleClass(i)">
                        <a href="{{this.getBasePath()}}/projects/{{item.id}}"
                           [textContent]="shorten(item.name)" [title]="item.name"></a>
                    </td>
                    <td *ngIf="item"
                        [ngClass]="getPurpleClass(i)" [textContent]="(item.value*100).toFixed(2)+'%'">
                    </td>
                </tr>
                <tr *ngIf="loadingBudgets">
                    <td [ngClass]="getPurpleClass(0)" colspan="3">Загрузка...</td>
                </tr>
                <tr *ngIf="!loadingBudgets && budgetCount === 0">
                    <td [ngClass]="getPurpleClass(0)" colspan="3">Нет данных</td>
                </tr>
                </tbody>
            </table>
            <pagination-footer  [hidden]="budgetVisibility"
                                [pages]="budgetPages"
                                (changePageEvent)="loadBudgetsByPage($event)">

            </pagination-footer>
        </div>
    </div>
</div>
