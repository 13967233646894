import {Component, OnInit} from "@angular/core";
import {HalResourceService} from "core-app/features/hal/services/hal-resource.service";
import {PathHelperService} from "core-app/core/path-helper/path-helper.service";
import {CollectionResource} from "core-app/features/hal/resources/collection-resource";
import {HalResource} from "core-app/features/hal/resources/hal-resource";
import {HttpClient, HttpParams} from "@angular/common/http";
import {StatusResource} from "core-app/features/hal/resources/status-resource";
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';

@Component({
  selector: 'colorlight-tab',
  templateUrl: './colorlight-tab.html',
})
export class ColorlightTabComponent implements OnInit {
  types:any;
  types2:any;
  selectedValue:string;
  selectedValue2:string;
  statuses:any;
  selectedStatuses1:number[] = [];
  selectedStatuses2:number[] = [];

  constructor(protected halResourceService:HalResourceService,
              protected pathHelper:PathHelperService,
              readonly apiV3Service:ApiV3Service,
              protected http:HttpClient) {
  }

  ngOnInit() {
    this.halResourceService
      .get<CollectionResource<HalResource>>(this.apiV3Service.enumerations.path.toString() + '/ArbitaryObjectType', {})
      .toPromise()
      .then((resource:CollectionResource<HalResource>) => {
        this.types = resource.elements;
        this.selectedValue = resource.elements[0].id as string;

        this.types2 = resource.elements;
        this.selectedValue2 = resource.elements[0].id as string;
      });

    this.halResourceService
      .get<CollectionResource<StatusResource>>(this.apiV3Service.statuses.path.toString())
      .toPromise().then((resource:any) => {
        this.statuses = resource.elements;
        for (let i = 0; i < this.statuses.length; i++) {
          this.selectedStatuses1.push(this.statuses[i].id);
          this.selectedStatuses2.push(this.statuses[i].id);
        }
    });
  }

  selectStatus(value:number, checked:boolean, number:number) {
    if (checked) {
      if (number === 1) {
        this.selectedStatuses1.push(value);
      } else {
        this.selectedStatuses2.push(value);
      }
    } else {
      if (number === 1) {
        this.selectedStatuses1.splice(this.selectedStatuses1.indexOf(value), 1);
      } else {
        this.selectedStatuses2.splice(this.selectedStatuses2.indexOf(value), 1);
      }
    }
  }

  submit() {
    this.halResourceService
      .get<any>(this.apiV3Service.colorlight.path.toString(), {type: this.selectedValue, 'status[]': this.selectedStatuses1})
      .toPromise()
      .then((resource:{ path:string }) => {
        this.http.get(this.apiV3Service.vkladka1.path.toString(), {
          responseType: 'blob',
          params: {filepath: resource.path}
        }).subscribe(file => {
          const filename = resource.path.substring(resource.path.lastIndexOf('/') + 1);
          this.downloadFile(file, filename);
        });
      });
  }

  downloadFile(file:any, filename:string):void {
    const blob = new Blob([file], {type: 'application/ms-excel'});
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    // a.style = 'display: none';
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }


  //! TODO: раскомментировать при добавлении jopsd
  // submitsecond() {
  //   let params = new HttpParams().set("typeId", this.selectedValue2).set("status[]", this.selectedStatuses2.toString());
  //   this.http.get(this.pathHelper.javaUrlPath + '/generate_light_report/xlsx/params?', {
  //     params: params,
  //     // observe: 'response',
  //     responseType: 'arraybuffer'
  //   }).toPromise().then(
  //     (data) => {
  //       this.downloadFile(data, "colorlight.xlsx");
  //     }
  //   );
  // }
}
