import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'op-select-is-program-type-input',
  templateUrl: './select-is-program-type-input.component.html',
})
export class SelectIsProgramTypeInputComponent extends FieldType {

   protected typesProgram: { name: string, isProgram: boolean }[] = [
    {
      name: 'Паспорт программы',
      isProgram: true,
    },
    {
      name: 'Паспорт проекта',
      isProgram: false,
    },
  ]
}
