<div class="work-package--details--long-field work-packages--activity--add-comment">
  <div>
      <style type="text/css">
          table { width: 100%; }
          td.colA { width: 16%; height: 20px; text-align: center; font-size: 90%; min-width: 25px}
          td.colB { width: 25%; }
          th.colA { width: 16%; text-align: center; font-size: 95%; }
          .input-period { min-width: 70px; height: 25px; }
          .input-value { max-width: 70px; }
          div.buttons {
              display: inline-block;
              width: 60px;
              padding-left: 5px;
              padding-bottom: 15px;
          }
      </style>

    <div class="wp-relations-create">
      <div class="wp-relations-create-button hide-when-print"
           *ngIf="!showTargetsCreateForm">
        <div class="grid-block">
          <div class="grid-content collapse wp-inline-create-button">
            <a class="wp-inline-create--add-link relation-create"
               #focusAfterSave
               (accessibleClick)="toggleTargetsCreateForm()"
               href
               id="relation--add-relation">
              <op-icon icon-classes="icon icon-add"></op-icon>
              <span [textContent]="text.addNewTarget"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="loading-indicator--location"
           data-indicator-name="relationAddDynamic">
        <div class="v-align wp-relations-create--form" style="display: initial !important;"
             *ngIf="showTargetsCreateForm">
          <div>
            <label for="input_target" style="margin-top: 10px;">Целевой показатель</label>
            <select name="target" [required]="true" id="input_target" [(ngModel)]="wpTarget.target_id">
              <option *ngFor="let target of targets | keyvalue" value="{{ target.key }}">{{ target.value }}</option>
            </select>
            <br/>
            <div>
<!--            <div *ngIf="editedTarget != null && editedTarget.id == 0">-->
                <ng-template [ngTemplateOutlet]="createTemplate">
                </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 20px;">
      <ul *ngFor="let targetId of wpTargetIds">
        <li>
          <div style="padding: 3px; margin: 3px;">
              <h4>
                  <a href="{{ this.getAppBasePath() }}/projects/{{ workPackage.project.id }}/targets/{{ targetId }}/edit"
                     [textContent]="getTargetFromArr(targetId).name"  [title]=""></a>
              </h4>
              <table class="table table-striped">
              <thead>
                <tr>
                  <th class="colA">Год</th>
                  <th class="colA">Квартал</th>
                  <th class="colA">Месяц</th>
                  <th class="colA">План. знач.</th>
                  <th class="colA">Факт. знач.</th>
                  <th class="colA">Обновлено</th>
                  <th><div class="buttons" style="width: 74px"></div></th>
                </tr>
              </thead>
            </table>
            <table *ngFor="let value of wpTargets">
              <tbody>
                <tr *ngIf="value.target_id === targetId">
                  <ng-template [ngTemplateOutlet]="loadTemplate(value)"
                                 [ngTemplateOutletContext]="{ $implicit: value }">
                  </ng-template>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ul>

    </div>
  </div>


</div>


<ng-template #createTemplate>
<table>
  <tr>
    <th>Год</th>
    <th>Квартал</th>
    <th>Месяц</th>
    <th>План. знач.</th>
    <th>Факт. знач.</th>
  </tr>
  <tr>
    <td class="colA">
        <input class="input-period" type="number" step="1" [(ngModel)]="wpTarget.year" />
    </td>
    <td class="colA">
        <select [(ngModel)]="wpTarget.quarter" class="input-period"  >
            <option value="" selected="selected"></option>
            <option ng-click="changeQuarter(1)" value="1">1-й квартал</option>
            <option ng-click="changeQuarter(2)" value="2">2-й квартал</option>
            <option ng-click="changeQuarter(3)" value="3">3-й квартал</option>
            <option ng-click="changeQuarter(4)" value="4">4-й квартал</option>
        </select>

    </td>
    <td class="colA">
        <select [(ngModel)]="wpTarget.month" class="input-period"  >
            <option value="" selected="selected"></option>
            <ng-template [ngTemplateOutlet]="loadTemplateMonth(wpTarget.quarter)">
            </ng-template>
        </select>
    </td>
    <td class="colA">
        <input [(ngModel)]="wpTarget.plan_value" class="input-value" />
    </td>
    <td class="colA">
        <input [(ngModel)]="wpTarget.value" class="input-value" />
    </td>
  </tr>
</table>
    <div style='margin-top: 10px; float: right'>
        <accessible-by-keyboard
                (execute)='createTarget()'
                linkClass='wp-create-relation--save'
                aria-hidden='false'>
            <op-icon icon-classes='icon-checkmark -padded' [icon-title]='text.save'></op-icon>
        </accessible-by-keyboard>
        <accessible-by-keyboard
                (execute)='toggleTargetsCreateForm()'
                linkClass='wp-create-relation--cancel'
                aria-hidden='false'>
            <op-icon icon-classes='icon-remove -padded' [icon-title]='text.abort'></op-icon>
        </accessible-by-keyboard>
    </div>
</ng-template>


<ng-template #editTemplateFull>
    <td class="colA">
      <input type="number" step="1" [(ngModel)]="editedTarget.year" class="input-period" />
    </td>
    <td class="colA">
        <select [(ngModel)]="editedTarget.quarter" class="input-period">
            <option value="" selected="selected"></option>
            <option ng-click="changeQuarter(1)" value="1">1-й квартал</option>
            <option ng-click="changeQuarter(2)" value="2">2-й квартал</option>
            <option ng-click="changeQuarter(3)" value="3">3-й квартал</option>
            <option ng-click="changeQuarter(4)" value="4">4-й квартал</option>
        </select>
    </td>
    <td class="colA">
        <select [(ngModel)]="editedTarget.month" class="input-period" >
            <option value="" selected="selected"></option>
            <ng-template [ngTemplateOutlet]="loadTemplateMonth(editedTarget.quarter)">
            </ng-template>
        </select>
    </td>
    <td class="colA">
        <div *ngIf="planValueCanEdit;else onlyRead">
            <input [(ngModel)]="editedTarget.plan_value" class="input-value" />
        </div>
    </td>
    <td class="colA">
        <input [(ngModel)]="editedTarget.value" class="input-value" />
    </td>
    <td>
      <accessible-by-keyboard
              (execute)="updateTarget(editedTarget)"
              linkClass="wp-create-relation--save"
              aria-hidden="false">
        <op-icon icon-classes="icon-checkmark -padded" [icon-title]="text.save"></op-icon>
      </accessible-by-keyboard>
      <accessible-by-keyboard
              (execute)="cancelEdit()"
              linkClass="wp-create-relation--cancel"
              aria-hidden="false">
        <op-icon icon-classes="icon-remove -padded" [icon-title]="text.abort"></op-icon>
      </accessible-by-keyboard>
    </td>
</ng-template>

<ng-template #onlyRead>
    <td class="colA">{{ editedTarget.plan_value }} </td>
</ng-template>

<ng-template #editTemplate2 let-value>
    <td class="colA">{{ value.year }}</td>
    <td class="colA">{{ value.quarter }}</td>
    <td class="colA">{{ value.month }}</td>
    <td class="colA">{{ value.plan_value }}</td>
    <td class="colA">
        <input [(ngModel)]="editedTarget.value" class="input-value" />
    </td>
    <td>
            <accessible-by-keyboard
                    (execute)="updateTarget(editedTarget)"
                    linkClass="wp-create-relation--save"
                    aria-hidden="false">
                <op-icon icon-classes="icon-checkmark -padded" [icon-title]="text.save"></op-icon>
            </accessible-by-keyboard>
            <accessible-by-keyboard
                    (execute)="cancelEdit()"
                    linkClass="wp-create-relation--cancel"
                    aria-hidden="false">
                <op-icon icon-classes="icon-remove -padded" [icon-title]="text.abort"></op-icon>
            </accessible-by-keyboard>
</td>
</ng-template>


<ng-template #readOnlyTemplate2 let-value>
    <td class="colA">{{ value.year }}</td>
    <td class="colA">{{ quarterNames[value.quarter] }}</td>
    <td class="colA">{{ months[value.month-1] }}</td>
    <td class="colA">{{ value.plan_value }}</td>
    <td class="colA">{{ value.value }}</td>
    <td class="colA">{{ value.updated_at }}</td>
    <td>
      <div class="buttons">
          <accessible-by-keyboard *ngIf="factValueCanEdit"
                                  (execute)="editTarget(value)"
                                  [linkTitle]="text.edit"
                                  class="edit-activity">
            <op-icon icon-classes="action-icon icon-edit" [icon-title]="text.edit"></op-icon>
          </accessible-by-keyboard>

          <accessible-by-keyboard *ngIf="planValueCanEdit"
                                  (execute)="deleteTarget(value)"
                                  aria-hidden="false"
                                  [linkAriaLabel]="text.removeButton"
                                  [linkTitle]="text.removeButton"
                                  linkClass="relation-row--remove-btn">
            <op-icon icon-classes="icon-remove -padded wp-relations--icon" [icon-title]="text.removeButton"></op-icon>
          </accessible-by-keyboard>
      </div>
    </td>
</ng-template>

<ng-template #monthOfQuarter1>
    <option value="1">Январь</option>
    <option value="2">Февраль</option>
    <option value="3">Март</option>
</ng-template>

<ng-template #monthOfQuarter2>
    <option value="4">Апрель</option>
    <option value="5">Май</option>
    <option value="6">Июнь</option>
</ng-template>

<ng-template #monthOfQuarter3>
    <option value="7">Июль</option>
    <option value="8">Август</option>
    <option value="9">Сентябрь</option>
</ng-template>

<ng-template #monthOfQuarter4>
    <option value="10">Октябрь</option>
    <option value="11">Ноябрь</option>
    <option value="12">Декабрь</option>
</ng-template>
