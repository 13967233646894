<div class="loading-indicator--location" data-indicator-name="pages-table">
    <table mat-table [dataSource]="dataSource" style="width: 100%; table-layout: fixed">
        <!--            наименование-->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef style="font-size: 14px">НАИМЕНОВАНИЕ</th>
            <td mat-cell *matCellDef="let positions">
                <a href="/admin/positions/{{positions.id}}/edit">{{positions.name}}</a>
            </td>
        </ng-container>
        <!--            удаление-->
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let positions">
                <a data-confirm="Вы уверены?" title="Удалить" rel="nofollow" data-method="delete" href="/admin/positions/{{positions.id}}">
                    <i class="icon icon-delete" aria-hidden="true"></i>
                </a>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="wp-inline-create-button">
        <a style="position: relative; top: 10px; left: 18px" aria-label="Добавить должность" class="wp-inline-create--add-link" title="Добавить должность" href="/admin/positions/new">
            <i class="icon icon-add" aria-hidden="true"></i>
        </a>
    </div>

    <mat-paginator [length]="paginatorSettings.totalElements"
                   [pageSize]="paginatorSettings.size"
                   [pageIndex]="paginatorSettings.page"
                   (page)="pageChanged($event)"
                   [pageSizeOptions]="[5,10,25]">
    </mat-paginator>
</div>

