<div style="display: flex">
    <h2>{{formTitle}}</h2>
    <div style="display: flex; margin-inline-start: auto; align-items: center">
        <div class="toolbar-item">
            <a class="button -alt-highlight" href="#" (click)="showAddForm()"><i class="button--icon icon-add"></i><span class="button--text">ЛБО</span></a>
        </div>
    </div>
</div>
<mat-card id="add-form" style="width: 500px" [hidden]="addFormDisable" [formGroup]="add_lbo_form">
    <mat-form-field style="width: 100px; margin-right: 10px" [floatLabel]="'always'">
        <input matInput placeholder="Год" formControlName="year">
    </mat-form-field>
    <mat-form-field style="width: 200px; margin-right: 10px" [floatLabel]="'always'">
        <input matInput placeholder="Сумма" formControlName="sum">
    </mat-form-field>
    <i class="button--icon icon-close lbo-close-button" (click)="closeAddForm()"></i>
    <button mat-raised-button style="background: #1A67A3; color: #FFFFFF" (click)="addNewLbo()">
        <i class="icon-checkmark"></i>
        Добавить
    </button>
</mat-card>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Index Column-->
    <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef> № </th>
        <td mat-cell *matCellDef="let element; let i = index;">{{i + 1}}</td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef [hidden]="true"></th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Year Column -->
    <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef> Год </th>
        <td mat-cell *matCellDef="let element"> {{element.year}} </td>
    </ng-container>

    <!-- Sum Column -->
    <ng-container matColumnDef="sum">
        <th mat-header-cell *matHeaderCellDef> Сумма </th>
        <td mat-cell *matCellDef="let element"> {{element.sum}} </td>
    </ng-container>

    <!-- Delete Column -->
    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <i class="button--icon icon-delete" style="cursor: pointer; color: #1A67A3" (click)="deleteLbo(element.id)"></i>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

