
<div class="work-package--details--long-field work-packages--activity--add-comment hide-when-print">
    <style type="text/css">
        table { width: 100%; }
        td.colA { width: 20%; }
        td.colB { width: 14%; }
    </style>

    <div class="wp-relations-create">
        <div class="wp-relations-create-button hide-when-print"
             *ngIf="!showContractCreateForm">
            <div class="grid-block">
                <div class="grid-content collapse wp-inline-create-button">
                    <a class="wp-inline-create--add-link relation-create"
                       (accessibleClick)="toggleContractAddForm()"
                       href>
                        <op-icon icon-classes="icon icon-add"></op-icon>
                        <span>Добавить контракт</span>
                    </a>
                </div>
            </div>
        </div>
        <div>
            <div class="v-align wp-relations-create--form" *ngIf="showContractCreateForm" style="display: initial !important;">
                <section>
                    <h4>Введите данные</h4>
                    <div>
                        <div>
                            <label for="select_contract" class="-required">Гос. Контракт
                                <span class="form--label-required" aria-hidden="true">*</span>
                            </label>
                            <select name="contract" [required]="true" id="select_contract" [(ngModel)]="wpContract.contract_id">
                                <option *ngFor="let contract of contracts" value="{{ contract.id }}">{{ contract.contractSubject }}</option>
                            </select>
                        </div>
                        <div style="float:bottom">
                            <label for="input_comment">Описание</label>
                            <textarea id="input_comment" name="comment" [(ngModel)]="wpContract.comment"></textarea>
                        </div>
                    </div>
                    <div style="margin-top: 10px; float: right">
                        <accessible-by-keyboard
                                (execute)="wpContract.id ? editContract() : createContract()"
                                [isDisabled]="isDisabled"
                                linkClass="wp-create-relation--save"
                                aria-hidden="false">
                            <op-icon icon-classes="icon-checkmark -padded" [icon-title]="text.save"></op-icon>
                        </accessible-by-keyboard>
                        <accessible-by-keyboard
                                (execute)="toggleContractAddForm()"
                                linkClass="wp-create-relation--cancel"
                                aria-hidden="false">
                            <op-icon icon-classes="icon-remove -padded" [icon-title]="text.abort"></op-icon>
                        </accessible-by-keyboard>
                    </div>
                </section>
            </div>
        </div>
    </div>

    <section style="margin-top: 20px;">
        <div class="wp-relations-hierarchy-section">
            <div class="attributes-group--header">
                <div class="attributes-group--header-container">
                    <h3 class="attributes-group--header-text">
                        {{'Контракты'}}
                    </h3>
                </div>
            </div>
        </div>
        <div class="wp-relations-create">
        </div>
        <div class="container">
            <div *ngFor="let contract of wpContracts" class="contract-row">
                <ng-template [ngTemplateOutlet]="readOnlyTemplate3"
                             [ngTemplateOutletContext]="{ $implicit: contract }">
                </ng-template>
            </div>
        </div>
    </section>

</div>
<ng-template #readOnlyTemplate3 let-contract>
    <div class="contract-cells">
        <div class="contract-cell">
            <div style="display: block; float: left; position: relative; width: 100%;">
                <label for="contract_subject">Название</label>
                <span id="contract_subject">
          {{contract.subject}}
        </span>
            </div>
            <div style="display: block; float: left; position: relative; width: 100%;">
                <label for="comment">Комментарий</label>
                <span id="comment">
          {{contract.comment}}
        </span>
            </div>
        </div>
        <div class="contract-cell">
            <div style="display: block; float: left; position: relative; width: 100%;">
                <label for="executor">Исполнитель</label>
                <span id="executor">
          {{contract.executor}}
        </span>
            </div>
            <div style="display: block; float: left; position: relative; width: 100%;">
                <label for="customer">Заказчик</label>
                <span id="customer">
          {{contract.gosZakaz}}
        </span>
            </div>
        </div>
        <div class="contract-cell">
            <div style="display: block; float: left; position: relative; width: 100%;">
                <label>Цена</label>
                <span *ngIf="contract.isApprove; else created" style="color: green;">
          {{contract.price}}
        </span>
                <ng-template #created>
          <span style="color: lightcoral;">
            {{contract.price}}
          </span>
                </ng-template>
            </div>
            <div style="display: block; float: left; position: relative; width: 100%;">
                <label for="contract-date">Дата контракта</label>
                <span id="contract-date">
          {{contract.contractDate}}
        </span>
            </div>
        </div>
        <div class="contract-cell">
            <div style="display: block; float: left; position: relative; width: 100%;">
                <label for="contract-author">Добавил</label>
                <span id="contract-author">
          {{ contract.author }}
        </span>
            </div>
            <div style="display: block; float: left; position: relative; width: 100%;">
            </div>
        </div>
    </div>
    <div class="buttons">
        <accessible-by-keyboard (execute)="toggleContractAddForm(contract)"
                                [linkTitle]="text.edit"
                                class="edit-activity">
            <op-icon icon-classes="action-icon icon-edit" [icon-title]="text.edit"></op-icon>
        </accessible-by-keyboard>
        <accessible-by-keyboard (execute)="deleteContract(contract)"
                                aria-hidden="false"
                                [linkAriaLabel]="text.removeButton"
                                [linkTitle]="text.removeButton"
                                linkClass="relation-row--remove-btn">
            <op-icon icon-classes="icon-remove -padded wp-relations--icon" [icon-title]="text.removeButton"></op-icon>
        </accessible-by-keyboard>
    </div>
</ng-template>
