import {Component} from "@angular/core";
import {DiagramHomescreenResource} from "core-app/features/hal/resources/diagram-homescreen-resource";
import {HalResourceService} from "core-app/features/hal/services/hal-resource.service";
import {PathHelperService} from "core-app/core/path-helper/path-helper.service";
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';


@Component({
  templateUrl: './budget-tab.html'
})
export class BudgetTabComponent {
  protected readonly appBasePath:string;
  public budgetFederalChartData:any[];
  public budgetFederalChartLabel:string;
  public budgetRegionalChartData:any[];
  public budgetRegionalChartLabel:string;
  public budgetOtherChartData:any[];
  public budgetOtherChartLabel:string;
  constructor(
    protected halResourceService:HalResourceService,
    readonly apiV3Service:ApiV3Service,
    protected pathHelper:PathHelperService) {
    this.appBasePath = window.appBasePath ? window.appBasePath : '';
  }
  ngOnInit() {
    this.getFederalBudgetChart();
    this.getRegionalBudgetChart();
    this.getOtherBudgetChart();
  }
  public getFederalBudgetChart() {
    this.halResourceService
      .get<DiagramHomescreenResource>(`${this.apiV3Service.diagrams.path.toString()}/fed_budget`)
      .toPromise()
      .then((resource:DiagramHomescreenResource) => {
        this.budgetFederalChartData = resource.data;
        this.budgetFederalChartLabel = resource.label;
      });
  }
  public getRegionalBudgetChart() {
    this.halResourceService
      .get<DiagramHomescreenResource>(`${this.apiV3Service.diagrams.path.toString()}/reg_budget`)
      .toPromise()
      .then((resource:DiagramHomescreenResource) => {
        this.budgetRegionalChartData = resource.data;
        this.budgetRegionalChartLabel = resource.label;
      });
  }
  public getOtherBudgetChart() {
    this.halResourceService
      .get<DiagramHomescreenResource>(`${this.apiV3Service.diagrams.path.toString()}/other_budget`)
      .toPromise()
      .then((resource:DiagramHomescreenResource) => {
        this.budgetOtherChartData = resource.data;
        this.budgetOtherChartLabel = resource.label;
      });
  }
}
