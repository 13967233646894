<div style="display: flex">
    <h2>KPI</h2>
    <div style="display: flex; margin-inline-start: auto; align-items: center">
        <div class="toolbar-item">
            <a class="button -alt-highlight" href="/kpi/new"><i class="button--icon icon-add"></i><span class="button--text">Создать</span></a>
        </div>
    </div>
</div>
<div class="loading-indicator--location" data-indicator-name="pages-table">
    <table mat-table [dataSource]="dataSource" style="width: 100%; table-layout: fixed">
        <!--            Название-->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Название</th>
            <td mat-cell *matCellDef="let kpi">
                <a href="/kpi/{{kpi.id}}/edit">{{kpi.name}}</a>
            </td>
        </ng-container>
        <!--            Запрос-->
        <ng-container matColumnDef="query">
            <th mat-header-cell *matHeaderCellDef>Запрос</th>
            <td mat-cell *matCellDef="let kpi" [matTooltip]="kpi.query || '-'">
                <span class="ellipsis">{{kpi.query || '-'}}</span>
            </td>
        </ng-container>
        <!--            Удаление-->
        <ng-container matColumnDef="is_deleted">
            <th mat-header-cell *matHeaderCellDef style="width: 100px">Удалить</th>
            <td mat-cell *matCellDef="let kpi" >
                <button mat-icon-button (click)="deleteKPI(kpi)">
                    <i class="icon-delete"></i>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="paginatorSettings.totalElements"
                   [pageSize]="paginatorSettings.size"
                   [pageIndex]="paginatorSettings.page"
                   (page)="pageChanged($event)"
                   [pageSizeOptions]="[5,10,20]">
    </mat-paginator>
</div>
